import React from "react";

import {
    Wrapper, Image,
} from './Profile.style';

import me from '../../../assets/me.jpg';

const Profile = () => {
    return (
        <Wrapper>
            <Image src={me} />
        </Wrapper>
    );
};

export { Profile };
export default Profile;

