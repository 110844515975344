import styled, { css } from 'styled-components';

import { Section } from '../UI/Section';
import { H3, Span } from '../UI/Typography';

const WrapperStyled = styled(Section)`
`;

const HeaderStyled = styled.header`
`;

const ContentStyled = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const ULWrapperStyled = styled.div`
    display: flex;
    justify-content: center;
    flex: 1 0 100%;
    
    @media screen and (min-width: 480px) {
        &:nth-child(n + 2) {
            padding-left: ${({ theme }) => theme.spacing[2]};
        }
        justify-content: flex-start;
        flex: 1 0 50%;
    }
    
    @media screen and (min-width: 750px) {
        flex: 1 0 25%;
    }
`;

const ULStyled = styled.ul`
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 150px;
`;

const LIStyled = styled.li`
    margin-bottom: ${({ theme }) => theme.spacing[0.5]};
`;

const H3Styled = styled(H3)`
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: ${({ theme }) => theme.spacing[2]};
    text-transform: uppercase;
`;

const Strong = styled(Span)`
    font-weight: 700;
`;

const Link = styled.a`
    transition: all .2s ease-in-out;
    font-weight: 300;
    text-decoration: none;
    padding: 2px 3px;
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.palette.linkSoft};
    color: inherit;
    transition-duration: 0.3s;
    
    &:hover {
        border-color: transparent;
        box-shadow: 0 10px 5px -10px ${({ theme }) => theme.palette.grey};
    }
`;

const InfoStyled = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 12px;
    margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

export {
    WrapperStyled, HeaderStyled, ContentStyled,
    Strong, Link,
    ULWrapperStyled, ULStyled, LIStyled, H3Styled, InfoStyled,
};
