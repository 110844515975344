import React from 'react';
import PropTypes from 'prop-types';
import { renderRoutes, matchRoutes } from 'react-router-config';

import App from './pages/App/';
import Home from './pages/Home/';
import Experience from './pages/Experience/';
import Resume from './pages/Resume/';
import ResumeFull from './pages/ResumeFull/';
import ResumeNew from './pages/ResumeNew/';
import ResumeATS from './pages/ResumeATS/';

const routes = [
    {
        component: App,
        routes: [
            {
                path: '/',
                exact: true,
                component: Home,
//                 transition: 'message',
            },
            {
                path: '/experience/:slug/',
                exact: true,
                component: Experience,
//                 transition: 'star',
            },
            {
                path: '/resume',
                exact: true,
                component: ResumeFull,
            },
            {
                path: '/cv',
                exact: true,
                component: ResumeFull,
            },
            {
                path: '/cv-ats/',
                exact: true,
                component: ResumeATS,
            },
            // {
            //     path: '/resume-new',
            //     exact: true,
            //     component: Resume,
            // },
        ],
    },
];

/**
 * Find preload sagas
 * @param location - current location
 * @returns {Array}
 */
const getPreloadSagasWithArgs = (location) => {
    const basename  = '';
    const pathname = location.pathname.slice(basename.length);
    const branch = matchRoutes(routes, pathname);
    const preloadSagasWithArgs = [];

    branch.forEach(({ route, match }) => {
        if (route.component.getPreloadSaga) {
            preloadSagasWithArgs.push({
                saga: route.component.getPreloadSaga(),
                args: {
                    params: match.params,
                    pathname,
                    search: location.search,
                    hash: location.hash,
                },
            });
        }
    });

    return preloadSagasWithArgs;
};

// Render routes
const renderChildRoutes = ({ route }) => renderRoutes(route.routes);

class ContextProvider extends React.Component {
    static childContextTypes = {
        insertCss: PropTypes.func,
    };

    getChildContext() {
        return { ...this.props.context };
    }

    render() {
        // return (
        //     <TransitionGroup>
        //         <CSSTransition
        //             timeout={500}
        //             classNames="fade"
        //         >
        //             {React.Children.only(this.props.children)}
        //         </CSSTransition>
        //     </TransitionGroup>
        // );
        // return (
        //     <Route
        //         render={({ location }) => (
        //             <TransitionGroup>
        //                 <CSSTransition key={location.key} classNames="fade" timeout={3000}>
        //                     {React.Children.only(this.props.children)}
        //                 </CSSTransition>
        //             </TransitionGroup>
        //         )}
        //     />
        // )
        return React.Children.only(this.props.children);
    }
}

ContextProvider.propTypes = {
    context: PropTypes.object.isRequired,
};

export { renderChildRoutes, ContextProvider, getPreloadSagasWithArgs };

export default routes;
