import styled, { css } from 'styled-components';

const LayoutMainPage = styled.div`
    display: grid;
    grid-gap: 0;
    grid-template-columns: 230px calc(100% - 230px);
    width: 21cm;
    height: 29.7cm;
    margin: 0 auto;
    page-break-after: always;
`;

const LayoutSecondPage = styled(LayoutMainPage)``;

const Side = styled.div`
    grid-column: 1;
    grid-row: 1;
    background-color: #474747;
    min-height: 100px;
`;

const Main = styled.div`
    grid-column: 2;
    grid-row: 1;
    background-color: #e1e1e1;
`;

const MainSecond = styled(Main)`
    padding-top: ${({ theme }) => theme.spacing[6] };
`;

const LeftSide = styled.div`
    position: absolute;
    width: 50%;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #474747;
    z-index: -1;
    
    @media print{
       display:none;
    }
`;

const RightSide = styled.div`
    position: absolute;
    width: 50%;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #e1e1e1;
    z-index: -1;
    
    @media print{
       display:none;
    }
`;

export { LayoutMainPage, LayoutSecondPage, Side, Main, MainSecond, LeftSide, RightSide };
