// @flow
import type { LocaleT } from '../types';

export const ActionTypes = {
    SETLOCALE: 'app/set-locale',
    LOAD_EXPERIENCES: 'app/load-experiences',
    LOAD_EXPERIENCE: 'app/load-experience',
    EXPERIENCES_LOADED: 'app/experiences-loaded',
    EXPERIENCE_LOADED: 'app/experience-loaded',
};

export const setLocale = (locale: LocaleT) => ({
    type: ActionTypes.SETLOCALE,
    data: locale,
});

export const loadExperiences = () => ({
    type: ActionTypes.LOAD_EXPERIENCES,
});

export const loadExperience = slug => ({
    type: ActionTypes.LOAD_EXPERIENCE,
    slug,
});
