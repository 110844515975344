const createAction = (prefix, name = prefix, ...argNames) => {
    const type = `${prefix}/${name}`;
    return {
        ACTION: type,
        create: (...args) => {
            const action = { type };
            argNames.forEach((arg, index) => {
                action[argNames[index]] = args[index];
            });
            return action;
        },
    };
};

const prefix = 'PAGE';

export const preload = createAction(prefix, 'PRELOAD', 'pageId', 'params', 'search', 'pathname');
export const preloaded = createAction(prefix, 'PRELOADED', 'pageId');
export const load = createAction(prefix, 'LOAD', 'pageId', 'params', 'search', 'pathname');
export const loaded = createAction(prefix, 'LOADED', 'pageId');
