// Import images
import university from './assets/img/university.png';
import secondary from './assets/img/secondary.png';

const educations = [
    {
        type: 'university',
        yearFinished: 2014,
        schoolLink: 'https://fri.uni-lj.si/',
        logo: university,
    },
    {
        type: 'secondary',
        yearFinished: 2004,
        schoolLink: 'http://ker.sc-celje.si/',
        logo: secondary,
    },
];

export {
    educations,
};
