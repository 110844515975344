import styled, { css } from 'styled-components';

const Languages = styled.div`
    position: fixed;
    top: 0;
    right: 0;
`;

const LangImg = styled.img`
    width: 1.6666em;
    height: 1em;
    margin: ${({ theme }) => theme.spacing[1]};
    cursor: pointer;
`;

export { Languages, LangImg };
