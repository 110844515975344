import styled, { css } from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
    padding: 30px;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 100%;
    filter: grayscale(40%);
`;

export { Wrapper, Image };
