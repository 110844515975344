const interests = [
    'traveling',
    'snowboarding',
    'video-editing',
    'diy',
    'game-development',
];

export {
    interests,
};
