/* eslint-disable no-underscore-dangle */
import config from './config';

// Singleton that is exported
const environment = {
    vars: {},
    ssr: true,
};

export const getServerEnvironment = () => {
    const environments = {
        common: {
            env: config.env,
            mode: config.mode,
            basename: config.basename,
            staticPath: config.staticPath,
            host: config.host,
            port: config.port,
        },
        test: {},
        development: {},
        staging: {},
        production: {},
    };

    return {
        vars: { ...environments.common, ...environments[environments.common.mode] },
        ssr: true,
    };
};

export const getBrowserEnvironment = () => window.__ENVIRONMENT__;

// Sets the singleton
export const configureEnvironment = (newEnvironment) => {
    environment.vars = newEnvironment.vars;
    environment.ssr = newEnvironment.ssr;
};

export default environment;
