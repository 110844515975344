import React, { Component } from 'react';

import { translate, Trans } from 'react-i18next';

import AppSettings from '../../settings';

import {
    HeaderStyled,
    WrapperStyled,
    HeaderContent,
    H1Styled, H2Styled, Description,
    Strong, Link,
    ULStyled, LIStyled,
    IconStyled,
} from './Intro.style';

type Props = {
    t?: Function,
};

class Intro extends Component<Props> {
    constructor(props) {
        super(props);

        this.goToNextSection = this.goToNextSection.bind(this);
    }

    goToNextSection() {
        window.scrollTo({
            top: window.innerHeight,
            behavior: "smooth"
        });
    }

    render() {
        const { t } = this.props;

        return (
            <WrapperStyled>
                <HeaderStyled>
                    <HeaderContent>
                        <H1Styled>{t('components:intro.title')}</H1Styled>
                        <H2Styled>
                            <Trans i18nKey="components:intro.text">
                                I'm <Strong>{t('common:fullName')}</Strong>, an utter tech-aholic, incessant learner and a passionate traveler
                            </Trans>
                        </H2Styled>
                        <Description>
                            <Trans i18nKey="component:intro.getInTouch">
                                {t('components:intro.getInTouch')}
                            </Trans>
                            <ULStyled>
                                <LIStyled>
                                    <Link href={AppSettings.links.linkedIn} rel="noreferrer">{t('common:linkedIn')}</Link>
                                </LIStyled>
                                <LIStyled>
                                    <Link
                                        rel="noreferrer"
                                        href={`mailto:${AppSettings.links.email}`}
                                    >
                                        {t('common:email')}
                                    </Link>
                                </LIStyled>
                            </ULStyled>
                        </Description>
                    </HeaderContent>
                    <IconStyled type={"arrow_downward"} onClick={this.goToNextSection} />
                </HeaderStyled>
            </WrapperStyled>
        );
    }
}

export { Intro };
export default translate()(Intro);

