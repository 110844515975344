import { put, takeEvery, all, take } from 'redux-saga/effects';

import * as pageActions from '../../components/Page/actions';
import { ActionTypes, loadExperiences } from '../../actions';

export function* loadExperiencesData() {
    yield put(loadExperiences());
}

function* load(action) {
    yield loadExperiencesData();
    yield put(pageActions.loaded.create('Resume'));
}

function* preload(action) {
    yield loadExperiencesData();
    // Block until post is loaded
    yield take([ActionTypes.EXPERIENCES_LOADED]);
    yield put(pageActions.preloaded.create('Resume'));
}

export default function* pageSaga() {
    yield all([
        takeEvery(action => action.type === pageActions.preload.ACTION && action.pageId === 'Resume', preload),
        takeEvery(action => action.type === pageActions.load.ACTION && action.pageId === 'Resume', load),
    ]);
}
