import React, { Fragment } from "react";

import { translate } from 'react-i18next';

import {
    EducationWrapper, EducationTitle,
    EducationList, EducationDescription, EducationSubTitle, EducationListItem, EducationDate,
} from './Education.style';

import { educations } from '../../Education/Education.constants';

const Education = ({ t }) => {

    const renderItem = (education) => {
        const {
            type, yearFinished,
        } = education;

        return (
            <Fragment>
                <EducationSubTitle dark small>
                    {t(`components:education.${type}_title`)}
                    <EducationDate>{yearFinished}</EducationDate>
                </EducationSubTitle>
                <EducationDescription dark>{t(`components:education.${type}_school`)}</EducationDescription>
            </Fragment>
        );
    };

    return (
        <EducationWrapper>
            {/* <EducationTitle dark>EDUCATION</EducationTitle> */}
            <EducationList>
                {educations.map(education => (
                    <EducationListItem key={education.type}>
                        {renderItem(education)}
                    </EducationListItem>
                ))}
            </EducationList>
        </EducationWrapper>
    );
};

export { Education };
export default translate()(Education);

