import React from "react";

import { Wrapper, Title, SubTitle, List, ListItem } from '../Typography'

const MySkills = () => {
    return (
        <Wrapper>
            <Title>MY SKILLS</Title>
            <SubTitle>PROFESSIONAL</SubTitle>
            <List>
                <ListItem>JavaScript</ListItem>
                <ListItem>Python</ListItem>
                <ListItem>Java</ListItem>
                <ListItem>Objective-C</ListItem>
                <ListItem>Swift</ListItem>
                <ListItem>Bash</ListItem>
                <ListItem>ReactJS & React Native</ListItem>
                <ListItem>Django</ListItem>
                <ListItem>NodeJS</ListItem>
                <ListItem>SQL & NoSQL & ES</ListItem>
                <ListItem>AWS</ListItem>
                <ListItem>iOS</ListItem>
                <ListItem>Android</ListItem>
            </List>
            <SubTitle>LANGUAGES</SubTitle>
            <List>
                <ListItem>English</ListItem>
                <ListItem>Slovenian</ListItem>
                <ListItem>German</ListItem>
            </List>
        </Wrapper>
    );
};

export { MySkills };
export default MySkills;

