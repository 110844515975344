import React, { Component } from 'react';

import { translate, Trans } from 'react-i18next';

import { H21, P } from '../UI/Typography';

import {
    HeaderStyled,
    WrapperStyled,
    PStyled,
    Strong,
} from './MyBackground.style';

type Props = {
    t?: Function,
};

class MyBackground extends Component<Props> {
    constructor(props) {
        super(props);
    }

    render() {
        const { t } = this.props;

        return (
            <WrapperStyled>
                <HeaderStyled>
                    <H21>{t('components:myBackground.title')}</H21>
                </HeaderStyled>
                <PStyled>
                    <Trans i18nKey="components:myBackground.section1">
                        I'm currently working as a Principal Engineer at <Strong>TrustedHousesitters.com</Strong> and overseeing development of the <Strong>Mobile App</Strong>Strong> and <Strong>Web</Strong>.
                        My recent work includes an iOS and an Android App using React Native, Web rewrite in ReactJS, Message queuing service, Search engine and a Media management system.
                        I am giving technical support, directions and mentorship to colleagues.
                    </Trans>
                </PStyled>
                <PStyled>{t('components:myBackground.section2')}</PStyled>
                <PStyled>{t('components:myBackground.section3')}</PStyled>
            </WrapperStyled>
        );
    }
}


export { MyBackground };
export default translate()(MyBackground);
