import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';

import { Section } from '../UI/Section';
import { H1, H3, H4, H5, Span, P } from '../UI/Typography';

import Icon from '../Icon';

const WrapperStyled = styled(Section)`
`;

const HeaderStyled = styled.header`
    margin-bottom: 56px;
    
    > h1 {
        margin-bottom: 0;
    }
`;

const ContentStyled = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
`;

const ULWrapperStyled = styled.div`
    display: flex;
    justify-content: center;
    flex: 1 0 100%;
    
    @media screen and (min-width: 480px) {
        padding-left: ${({ theme }) => theme.spacing[2]};
        flex: 1 0 50%;
    }
    
    @media screen and (min-width: 750px) {
        flex: 1 0 25%;
    }
`;

const ULStyled = styled.ul`
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
`;

const LIStyled = styled.li`
    margin-bottom: ${({ theme }) => theme.spacing[7]};
    padding-bottom: ${({ theme }) => theme.spacing[7]};
    box-shadow: 0 7px 7px -7px ${({ theme }) => theme.palette.hoverGrey};
    
    &:last-child {
        box-shadow: none;
    }
`;

const H3Styled = styled(H3)`
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: ${({ theme }) => theme.spacing[2]};
    text-transform: uppercase;
`;

const H4Styled = styled(H4)`
    margin-bottom: ${({ theme }) => theme.spacing[2]};
    text-transform: uppercase;
`;

const H5Styled = styled(H5)`
    margin-bottom: ${({ theme }) => theme.spacing[2]};
    line-height: 24px;
`;

const Strong = styled(Span)`
    font-weight: 700;
`;

const CompanyStyled = styled.div`
    max-width: 900px;
`;

const Company = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 720px) {
        flex-direction: row;
    }
`;

const CompanyHeader = styled(H1)`
    color: ${({ theme }) => theme.palette.linkDark};
    margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

const CompanyAbout = styled(P)`
    margin: ${({ theme }) => theme.spacing[4]} 0 ${({ theme }) => theme.spacing[4]};
`;

const CompanyContent = styled.div`

`;

const CompanyImageWrapper = styled.div`
    width: 88px;
    height: 64px;
    justify-content: center;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.spacing[2]};
    
    @media screen and (min-width: 480px) {
        display: flex;
    }
`;

const CompanyImage = styled.img`
    display: flex;
    max-width: 64px;
    max-height: 64px;
    width: auto;
    height: auto;
    margin-right: 24px;
`;

const CompanyLink = styled.a`
    text-decoration: none;
    position: relative;
    display: inline-block;
    box-shadow: 0 7px 7px -7px ${({ theme }) => theme.palette.linkSoft};
    margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const CompanyRoute = styled(Link)`
    text-decoration: none;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.linkDark};
    transition-duration: 0.3s;
    
    &:hover {
        i {
            transform: translateX(${({ theme }) => theme.spacing[0.5]});
        }
    }
`;

const IconRouteStyled = styled(Icon)`
    font-size: 1em;
    margin-bottom: ${({ theme }) => theme.spacing[0.5]};
    margin-left: ${({ theme }) => theme.spacing[1]};
    transition-duration: 0.3s;

`;

const CompanyInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    
    @media screen and (min-width: 720px) {
        height: 64px;
    }

    margin-bottom: ${({ theme }) => theme.spacing[3]};
`;

const CompanyTitle = styled(H3)`
    margin: 0;
    color: ${({ theme }) => theme.palette.grey};
    font-size: 22px;
    line-height: 32px;
    text-decoration: none;
    overflow-wrap: break-word;
    word-break: break-word;
    font-weight: 700;
    
    @media screen and (min-width: 480px) {
        font-size: 32px;
        line-height: 48px;
    }
`;

const TotalExperience = styled.span`
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
`;


const Accomplishments = styled.ul`
    padding: 0;
    margin-top: 0;
    margin-bottom: ${({ theme, showDetails }) => (showDetails ? theme.spacing[3] : theme.spacing[3])};
    list-style: none;
`;

const Accomplishment = styled.li`
    margin-bottom: ${({ theme }) => theme.spacing[1]};
    font-size: 16px;
    line-height: 24px;
    padding-left: ${({ theme }) => theme.spacing[2]};
    position: relative;
    
    &::before {
        content: "•";
        position: absolute;
        left: 0;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        margin-right: ${({ theme }) => theme.spacing[1]};
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

const Responsibilities = styled.ul`
    padding: 0;
    margin-top: ${({ theme }) => theme.spacing[3]};
    margin-bottom: ${({ theme, showDetails }) => (showDetails ? theme.spacing[5] : 0)};
    list-style: none;
`;

const Responsibility = styled.li`
    margin-bottom: ${({ theme }) => theme.spacing[1]};
    padding-left: ${({ theme }) => theme.spacing[2]};
    position: relative;
    
    &::before {
        content: "•";
        position: absolute;
        left: 0;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        margin-right: ${({ theme }) => theme.spacing[1]};
    }
`;

const Positions = styled.ul`
    list-style: none;
    position: relative;
    padding: 0 0 ${({ theme }) => theme.spacing[8]} 0;
    margin: 56px 0;
`;

const Position = styled.li`
    position: relative;
    margin: 0;
    
    &:nth-last-child(n + 2) {
        padding-bottom: ${({ theme }) => theme.spacing[6]};
    }
    
    @media screen and (min-width: 720px) {
        &::before {
            content: ' ';
            font-size: 1rem;
            line-height: 1.5;
            font-weight: 700;
            position: absolute;
            left: -63px;
            top: 0;
            background-color: ${({ theme }) => theme.palette.hoverGrey};
            width: 16px;
            height: 16px;
            border-radius: 16px;
        }

        &::after {
            content: ' ';
            font-size: 1rem;
            line-height: 1.5;
            font-weight: 700;
            position: absolute;
            left: -56px;
            top: 0;
            bottom: 0;
            background-color: ${({ theme }) => theme.palette.hoverGrey};
            width: 2px;
        }
        
        &:first-child::before {
            background-color: #879DAB;
        }
        &:first-child::after {
            background-image: linear-gradient(#879DAB, ${({ theme }) => theme.palette.hoverGrey});
        }
    }
    
    @media screen and (min-width: 720px) {
        &:nth-last-child(n + 2) {
            padding-bottom: ${({ theme }) => theme.spacing[8]};
        }
    }
`;

const PositionTitle = styled(H3)`
    margin: 0 0 8px 0;
    font-size: 20px;
    line-height: 24px;
`;

const PositionDuration = styled.div`
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
`;

const PositionLocation = styled.p`
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 24px;
    color: ${({ theme }) => theme.palette.textLight};
`;

const PositionDateWrapper = styled(Span)`
    &::after {
        content: '\\2022';
        font-weight: 700;
        margin: 0 ${({ theme }) => theme.spacing[1]};
    }
`;

const PositionStartDate = styled(Span)`
    font-size: 0.9rem;
`;

const PositionDateEnd = styled(Span)`
    font-size: 0.9rem;
`;

const ULPositions = styled.ul`
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    padding: 0;
    margin-top: ${({ theme }) => theme.spacing[3]};
    flex-direction: column-reverse;
`;

const LIPosition = styled.li`
    font-size: 16px;
    line-height: 24px;
    margin-bottom: ${({ theme }) => theme.spacing[2]};
    border-radius: 16px;
    position: relative;
    font-weight: 400;

    &:first-child {
        font-size: 20px;
        font-weight: 700;
    }
`;

const Stack = styled.ul`
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
`;

const StackItem = styled.li`
    background-color: #D9E0E3;
    color: #003242;
    padding: ${({ theme }) => `${theme.spacing[0.5]} ${theme.spacing[1.5]}`};
    padding-top: ${({ theme }) => theme.spacing[1]};
    text-transform: uppercase;
    font-size: 0.8rem;
    line-height: 1.5em;
    margin-right: ${({ theme }) => theme.spacing[2]};
    margin-bottom: ${({ theme }) => theme.spacing[1.5]};
    border-radius: 16px;
`;

const MoreLink = styled.div`
    ${({ theme }) => theme.typography.h5};
    color: ${({ theme }) => theme.palette.linkDark};
    margin-top: ${({ theme }) => theme.spacing[2]};
    margin-left: 94px;
    text-decoration: underline;
    position: relative;
    cursor: pointer;
    
    &::before {
        content: '☟';
        position: absolute;
        text-decoration: none;
        left: -24px;
    }
`;

const IconStyled = styled(Icon)`
    font-size: 24px;
    cursor: pointer;
    margin-bottom: 32px;
    transition-duration: 0.3s;
    
    &:hover {
        transform: translateX(-${({ theme }) => theme.spacing[0.5]});
    }
`;


export {
    WrapperStyled, HeaderStyled, ContentStyled,
    Strong, IconStyled,
    ULWrapperStyled, ULStyled, LIStyled, H3Styled, H4Styled, H5Styled,
    CompanyStyled,
    Company, CompanyHeader, CompanyAbout, CompanyContent,
    CompanyImageWrapper, CompanyImage, CompanyLink, CompanyRoute, TotalExperience, IconRouteStyled,
    CompanyInfoWrapper, CompanyTitle,
    Responsibilities, Responsibility,
    Accomplishments, Accomplishment,
    Positions, Position, PositionTitle, PositionLocation,
    PositionDuration, PositionDateWrapper, PositionStartDate, PositionDateEnd,
    ULPositions, LIPosition,
    Stack, StackItem,
    MoreLink,
};
