import React from "react";

import { Wrapper, Title, List, ListItem, SubSubTitle, Link } from "../Typography";

const SocialMedia = () => {
    return (
        <Wrapper>
            <Title>LINKS</Title>
            <List>
                <ListItem withSpace>
                    <SubSubTitle noMargin>Website</SubSubTitle>
                    <Link href="https://coder.si">coder.si</Link>
                </ListItem>
                <ListItem withSpace>
                    <SubSubTitle noMargin>LinkedIn</SubSubTitle>
                    <Link href="https://www.linkedin.com/in/timrijavec/">linkedin.com/in/timrijavec</Link>
                </ListItem>
                <ListItem>
                    <SubSubTitle noMargin>Github</SubSubTitle>
                    <Link href="https://github.com/timrc">github.com/timrc</Link>
                </ListItem>
            </List>
        </Wrapper>
    );
};

export { SocialMedia };
export default SocialMedia;

