import React, { Component } from 'react';

import { translate, Trans } from 'react-i18next';

import { H21, P } from '../UI/Typography';

import {
    HeaderStyled, WrapperStyled, ContentStyled,
    Strong, Link,
    ULWrapperStyled, ULStyled, LIStyled, H3Styled, InfoStyled,
} from './Skills.style';

import {
    languages,
    frameworks,
    tools,
    other,
} from './Skills.constants';

type Props = {
    t?: Function,
};

class Skills extends Component<Props> {
    constructor(props) {
        super(props);
    }

    render() {
        const { t } = this.props;

        return (
            <WrapperStyled>
                <HeaderStyled>
                    <H21>{t('components:skills.title')}</H21>
                </HeaderStyled>
                <ContentStyled>
                    <ULWrapperStyled>
                        <ULStyled>
                            <LIStyled>
                                <H3Styled>{t('components:skills.languages.title')}</H3Styled>
                            </LIStyled>
                            {languages.map(item => (
                                <LIStyled key={item.key}>
                                    <Link href={item.url} rel="noreferrer">
                                        {t(`components:skills.languages.${item.key}`)}
                                    </Link>
                                </LIStyled>
                            ))}
                        </ULStyled>
                    </ULWrapperStyled>
                    <ULWrapperStyled>
                        <ULStyled>
                            <LIStyled>
                                <H3Styled>{t('components:skills.frameworks.title')}</H3Styled>
                            </LIStyled>
                            {frameworks.map(item => (
                                <LIStyled key={item.key}>
                                    <Link href={item.url} rel="noreferrer">
                                        {t(`components:skills.frameworks.${item.key}`)}
                                    </Link>
                                </LIStyled>
                            ))}
                        </ULStyled>
                    </ULWrapperStyled>
                    <ULWrapperStyled>
                        <ULStyled>
                            <LIStyled>
                                <H3Styled>{t('components:skills.tools.title')}</H3Styled>
                            </LIStyled>
                            {tools.map(item => (
                                <LIStyled key={item.key}>
                                    <Link href={item.url} rel="noreferrer">
                                        {t(`components:skills.tools.${item.key}`)}
                                    </Link>
                                </LIStyled>
                            ))}
                        </ULStyled>
                    </ULWrapperStyled>
                    <ULWrapperStyled>
                        <ULStyled>
                            <LIStyled>
                                <H3Styled>{t('components:skills.other.title')}</H3Styled>
                            </LIStyled>
                            {other.map(item => (
                                <LIStyled key={item.key}>
                                    <Link href={item.url} rel="noreferrer">
                                        {t(`components:skills.other.${item.key}`)}
                                    </Link>
                                </LIStyled>
                            ))}
                        </ULStyled>
                    </ULWrapperStyled>
                </ContentStyled>
                <InfoStyled>
                    {t(`components:skills.info`)}
                </InfoStyled>
            </WrapperStyled>
        );
    }
}


export { Skills };
export default translate()(Skills);
