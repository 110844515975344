import React, { Component, Fragment, PureComponent } from 'react';
import Helmet from 'react-helmet';
import GA from 'react-ga';

import ThemeProvider from '../../components/ThemeProvider';
import theme from '../../themes/default';
import global from '../../themes/global';

// import { TransitionGroup, CSSTransition } from 'react-transition-group';
import posed, { PoseGroup } from 'react-pose';

import LanguagesSelector from '../../components/LanguagesSelector';

import { renderChildRoutes } from '../../routes';

import { Main, Page } from './App.style';

const helmetConstants = require('react-helmet/lib/HelmetConstants.js');
helmetConstants.HELMET_ATTRIBUTE = 'data-trh';

const RouteContainer = posed.div({
    enter: { opacity: 1, y: 0, beforeChildren: true },
    exit: { opacity: 0, y: 0 },
});

class App extends PureComponent {
    componentDidMount() {
        this.props.history.listen((location, action) => {
            if (action === 'PUSH') {
                setTimeout(() => {
                    window.scrollTo(0, 0);
                }, 0);
            }
        });

        GA.initialize('UA-56856033-1');
        GA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        global();

        const { location, route } = this.props;

        const { key } = location || {};
        const classNames = location.pathname === '/' ? 'message' : 'star';
        // return (
        //     <Fragment>
        //         <Helmet defaultTitle="Tim Rijavec" titleTemplate="%s – Coder.si"/>
        //         <ThemeProvider theme={theme}>
        //             <Main>
        //                 <TransitionGroup>
        //                     <CSSTransition
        //                         key={key}
        //                         timeout={250}
        //                         classNames={"message"}
        //                     >
        //                         <Page>
        //                             {renderChildRoutes({ route })}
        //                         </Page>
        //                     </CSSTransition>
        //                 </TransitionGroup>
        //                 <LanguagesSelector/>
        //             </Main>
        //         </ThemeProvider>
        //     </Fragment>
        // );

        return (
            <Fragment>
                <Helmet titleTemplate="Tim Rijavec - %s – Coder.si">
                    <title>Professional Bio</title>
                    <meta
                        name="description"
                        content="I'm Tim Rijavec, developer, deviser, dreamer, inventor, maker, incessant learner and a passionate traveler."
                    />
                    <meta
                        name="keywords"
                        content="tim rijavec, tim, rijavec, coder, web, developer, app, mobile, engineer, full stack"
                    />

                    <meta property="og:url"                content="https://www.coder.si/" />
                    <meta property="og:type"               content="website" />
                    <meta property="og:title"              content="Tim Rijavec - Professional Bio – Coder.si" />
                    <meta property="og:description"        content="I'm Tim Rijavec, developer, deviser, dreamer, inventor, maker, incessant learner and a passionate traveler." />
                    <meta property="og:image"              content="https://www.coder.si/static/assets/me.jpg" />

                    <meta name="generator" content="Coder Engine" />

                    <link
                        rel="apple-touch-icon"
                        sizes="57x57"
                        href="/static/assets/favicon/apple-icon-57x57.png"
                    />
                    <link
                        rel="apple-touch-icon"
                        sizes="60x60"
                        href="/static/assets/favicon/apple-icon-60x60.png"
                    />
                    <link
                        rel="apple-touch-icon"
                        sizes="72x72"
                        href="/static/assets/favicon/apple-icon-72x72.png"
                    />
                    <link
                        rel="apple-touch-icon"
                        sizes="76x76"
                        href="/static/assets/favicon/apple-icon-76x76.png"
                    />
                    <link
                        rel="apple-touch-icon"
                        sizes="114x114"
                        href="/static/assets/favicon/apple-icon-114x114.png"
                    />
                    <link
                        rel="apple-touch-icon"
                        sizes="120x120"
                        href="/static/assets/favicon/apple-icon-120x120.png"
                    />
                    <link
                        rel="apple-touch-icon"
                        sizes="144x144"
                        href="/static/assets/favicon/apple-icon-144x144.png"
                    />
                    <link
                        rel="apple-touch-icon"
                        sizes="152x152"
                        href="/static/assets/favicon/apple-icon-152x152.png"
                    />
                    <link
                        rel="apple-touch-icon"
                        sizes="180x180"
                        href="/static/assets/favicon/apple-icon-180x180.png"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        sizes="192x192"
                        href="/static/assets/favicon/android-icon-192x192.png"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        sizes="32x32"
                        href="/static/assets/favicon/favicon-32x32.png"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        sizes="96x96"
                        href="/static/assets/favicon/favicon-96x96.png"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        sizes="16x16"
                        href="/static/assets/favicon/favicon-16x16.png"
                    />
                    <link rel="manifest" href="/static/assets/favicon/manifest.json" />
                    <meta name="msapplication-TileColor" content="#ffffff" />
                    <meta
                        name="msapplication-TileImage"
                        content="/static/assets/favicon/ms-icon-144x144.png"
                    />
                    <meta name="theme-color" content="#ffffff" />
                </Helmet>

                <ThemeProvider theme={theme}>
                    <Main>
                        <PoseGroup>
                            <RouteContainer key={key || 'root'}>
                                {renderChildRoutes({ route })}
                            </RouteContainer>
                        </PoseGroup>
                        <LanguagesSelector />
                    </Main>
                </ThemeProvider>
            </Fragment>
        );
    }
}

export default App;
