import React, { Component, Fragment } from 'react';

import { translate, Trans } from 'react-i18next';

import {
    format,
    parse,
    differenceInCalendarDays,
} from 'date-fns';

import { connect } from 'react-redux';
import { getExperiences, getExperience } from '../../selectors';

import { H21, P } from '../UI/Typography';

import {
    HeaderStyled, WrapperStyled, ContentStyled,
    Strong,
    ULWrapperStyled, ULStyled, LIStyled, H3Styled, H4Styled, H5Styled,
    CompanyStyled, IconStyled,
    Company, CompanyHeader, CompanyAbout, CompanyContent,
    CompanyImageWrapper, CompanyImage, CompanyLink, CompanyRoute, TotalExperience, IconRouteStyled,
    CompanyInfoWrapper, CompanyTitle,
    Responsibilities, Responsibility,
    Accomplishments, Accomplishment,
    Positions, Position, PositionTitle, PositionLocation,
    PositionDuration, PositionDateWrapper, PositionStartDate, PositionDateEnd,
    ULPositions, LIPosition,
    Stack, StackItem,
    MoreLink,
} from './Experiences.style';

type Props = {
    t?: Function,
    history: Object,
};

class Experiences extends Component<Props> {
    constructor(props) {
        super(props);

        const { slug } = this.props;
        this.state = {
            showDetails: Boolean(slug),
        };

        this.onNavigateBackPress = this.onNavigateBackPress.bind(this);
    }

    onNavigateBackPress() {
        this.props.history.goBack();
    }

    renderPosition(position) {
        const { t } = this.props;

        const {
            title, location, startDate, endDate, stack,
            accomplishments, responsibilities,
        } = position;

        const dateFrom = parse(startDate);
        const dateTo = endDate ? parse(endDate) : new Date();
        const totalDaysOfExperience = differenceInCalendarDays(dateTo, dateFrom);
        const yearsDec = totalDaysOfExperience / 365;
        const years = parseInt(yearsDec, 10);
        const months = parseInt(Math.ceil((yearsDec - years) * 12), 10);

        let experienceText = '';
        if (years > 0) {
            experienceText += `${years} ${t('components:experiences.position.years', { count: years })}`;
        }
        if (months > 0) {
            if (years > 0) {
                experienceText += ` `;
            }
            experienceText += `${months} ${t('components:experiences.position.months', { count: months })}`;
        }

        return (
            <Fragment>
                <PositionTitle>{title}</PositionTitle>
                <PositionDuration>
                    <PositionDateWrapper>
                        <PositionStartDate>{format(startDate, 'MMM YY')}</PositionStartDate>
                        {" - "}
                        <PositionDateEnd>{endDate ? format(endDate, 'MMM YY') : t('components:experiences.position.present')}</PositionDateEnd>
                    </PositionDateWrapper>
                    <TotalExperience>{experienceText}</TotalExperience>
                </PositionDuration>
                <PositionLocation>{location}</PositionLocation>
                <H5Styled>{t('components:experiences.position.responsibilities')}</H5Styled>
                {Boolean(responsibilities.length) && (
                    <Responsibilities>
                        {responsibilities.map((item, i) => (
                            <Responsibility key={`responsibility_${i}`}>
                                {item}
                            </Responsibility>
                        ))}
                    </Responsibilities>
                )}
                {Boolean(accomplishments.length) && (
                    <Fragment>
                        <H5Styled>{t('components:experiences.position.accomplishments')}</H5Styled>
                        <Responsibilities>
                            {accomplishments.map((item, i) => (
                                <Responsibility key={`accomplishment_${i}`}>
                                    {item}
                                </Responsibility>
                            ))}
                        </Responsibilities>
                    </Fragment>
                )}
                <H5Styled>{t('components:experiences.position.stack')}</H5Styled>
                <Stack>
                    {stack.map((item, i) => (
                        <StackItem key={`stack_${i}`}>
                            {item}
                        </StackItem>
                    ))}
                </Stack>
            </Fragment>
        )
    }

    calculateTotalExperienceTime(positions) {
        return positions.reduce((days, position) => {
            const { startDate, endDate } = position;
            const dateFrom = parse(startDate);
            const dateTo = endDate ? parse(endDate) : new Date();
            return days + differenceInCalendarDays(dateTo, dateFrom);
        }, 0);
    }

    renderItem(slug, experience) {
        const { t } = this.props;

        const {
            company, companyLogo, companyLink, about,
            accomplishments, positions, projects,
        } = experience;

        const totalDaysOfExperience = this.calculateTotalExperienceTime(positions);
        const yearsDec = totalDaysOfExperience / 365;
        const years = parseInt(yearsDec, 10);
        const months = parseInt(Math.ceil((yearsDec - years) * 12), 10);

        let experienceText = '';
        if (years > 0) {
            experienceText += `${years} ${t('components:experiences.position.years', { count: years })}`;
        }
        if (months > 0) {
            if (years > 0) {
                experienceText += ` `;
            }
            experienceText += `${months} ${t('components:experiences.position.months', { count: months })}`;
        }

        let accomplishmentsData = Boolean(accomplishments.length) ? accomplishments : positions[0].responsibilities;

        const topAccomplishments = this.state.showDetails ? accomplishmentsData : accomplishmentsData.slice(0, 5);

        return (
            <CompanyStyled>
                {this.state.showDetails && (
                    <IconStyled type={"arrow_back"} onClick={this.onNavigateBackPress} />
                )}
                <Company>
                    <CompanyImageWrapper>
                        <CompanyImage src={`/static/assets/experiences/${companyLogo}`} alt={company} />
                    </CompanyImageWrapper>
                    <CompanyContent>
                        <CompanyInfoWrapper>
                            <CompanyTitle>{company}</CompanyTitle>
                            <TotalExperience>{experienceText}</TotalExperience>
                        </CompanyInfoWrapper>

                        {this.state.showDetails && (
                            <CompanyAbout>{about}</CompanyAbout>
                        )}

                        {!this.state.showDetails && (
                            <ULPositions>
                                {positions.map((position, i) => (
                                    <LIPosition key={`positions_${i}`}>{position.title}</LIPosition>
                                ))}
                            </ULPositions>
                        )}
                        {this.state.showDetails && (
                            <H5Styled>{t('components:experiences.position.accomplishments')}</H5Styled>
                        )}
                        <Accomplishments showDetails={this.state.showDetails}>
                            {topAccomplishments.map((item, i) => (
                                <Accomplishment key={`accomplishment_${i}`}>
                                    {item}
                                </Accomplishment>
                            ))}
                        </Accomplishments>
                        {this.state.showDetails && (
                            <Positions>
                                {positions.map((position, i) => (
                                    <Position key={`position_${i}`}>
                                        {this.renderPosition(position)}
                                    </Position>
                                ))}
                            </Positions>
                        )}

                        {!this.state.showDetails && (
                            <CompanyRoute to={`/experience/${slug}/`}>
                                <span>{t('components:experiences.more')}</span>
                                <IconRouteStyled type={'arrow_forward'} />
                            </CompanyRoute>
                        )}

                    </CompanyContent>
                </Company>
            </CompanyStyled>
        )
    }

    renderExperience() {
        const { experience, slug } = this.props;
        if (!experience) return null;

        const { company, companyLink, about } = experience;
        if (!company) return null;

        return (
            <WrapperStyled>
                <ContentStyled>
                    {this.renderItem(slug, experience)}
                </ContentStyled>
            </WrapperStyled>
        );
    }

    renderExperiences() {
        const { experiences } = this.props;
        if (!experiences) return null;

        const { t } = this.props;

        return (
            <WrapperStyled>
                <HeaderStyled>
                    <H21>{t('components:experiences.title')}</H21>
                </HeaderStyled>
                <ContentStyled>
                    <ULWrapperStyled>
                        <ULStyled>
                            {experiences.map(experience => (
                                <LIStyled key={experience.type}>
                                    {this.renderItem(experience.type, experience)}
                                </LIStyled>
                            ))}
                        </ULStyled>
                    </ULWrapperStyled>
                </ContentStyled>
            </WrapperStyled>
        );
    }

    render() {
        const { slug } = this.props;

        if (slug) return this.renderExperience();

        return this.renderExperiences();
    }
}


const mapStateToProps = state => ({
    experience: getExperience(state),
    experiences: getExperiences(state),
});

export { Experiences };
export default translate()(connect(mapStateToProps, null)(Experiences));
