import React, { Component, Fragment } from 'react';

import { translate, Trans } from 'react-i18next';

import { H21 } from '../UI/Typography';

import {
    HeaderStyled,
    WrapperStyled,
    ContentStyled, ULWrapperStyled, ULStyled, LIStyled,
    EducationStyled,
    EducationImage,
    EducationImageWrapper,
    EducationInfoWrapper, EducationLink, EducationSchool, EducationTitle, EducationYear
} from './Education.style';

import { educations } from './Education.constants';

type Props = {
    t?: Function,
};

class Education extends Component<Props> {
    constructor(props) {
        super(props);
    }

    renderItem(item) {
        const { t } = this.props;
        const { logo, type, schoolLink, yearFinished } = item;

        return (
            <Fragment>
                <EducationStyled>
                    <EducationImageWrapper>
                        <EducationImage src={logo} alt={type} />
                    </EducationImageWrapper>
                    <EducationInfoWrapper>
                        <EducationLink href={schoolLink} rel="noreferrer">
                            <EducationSchool>{t(`components:education.${type}_school`)}</EducationSchool>
                            <EducationTitle>{t(`components:education.${type}_title`)}</EducationTitle>
                            <EducationYear>{yearFinished}</EducationYear>
                        </EducationLink>
                    </EducationInfoWrapper>
                </EducationStyled>
            </Fragment>
        );
    }

    render() {
        const { t } = this.props;

        return (
            <WrapperStyled>
                <HeaderStyled>
                    <H21>{t('components:education.title')}</H21>
                </HeaderStyled>
                <ContentStyled>
                    <ULWrapperStyled>
                        <ULStyled>
                            {educations.map((item, i) => (
                                <LIStyled key={`item_${i}`}>
                                    {this.renderItem(item)}
                                </LIStyled>
                            ))}
                        </ULStyled>
                    </ULWrapperStyled>
                </ContentStyled>

            </WrapperStyled>
        );
    }
}

export { Education };
export default translate()(Education);
