/* eslint-disable react/sort-comp */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import preloadSaga from './sagas';
import * as actions from './actions';
import * as selectors from './selectors';

type Props = {
    match: Object,
    load: Function,
    location: Object,
    preloaded: boolean,
};

const asPage = (WrappedComponent, pageId, usePreload = false) => {
    class Page extends React.PureComponent<Props> {
        static getPreloadSaga() {
            return function* preload(location) {
                if (usePreload) {
                    yield preloadSaga(location, pageId);
                }
            };
        }

        componentDidMount() {
            if (!this.props.preloaded) {
                this.props.load(this.props.match.params, this.props.location.search, this.props.location.pathname);
            }
        }

        componentWillReceiveProps(nextProps) {
            if (
                JSON.stringify(nextProps.match.params) !== JSON.stringify(this.props.match.params) ||
                nextProps.location.search !== this.props.location.search
            ) {
                this.props.load(nextProps.match.params, nextProps.location.search, nextProps.location.pathname);
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapStateToProps = state => ({
        preloaded: selectors.getPreloaded(state, pageId),
    });

    const mapDispatchToProps = dispatch => ({
        load: (params, search, pathname) => {
            // Un-encode nested location data
            const parsedSearch = {};

            dispatch(actions.load.create(pageId, params, parsedSearch, pathname));
        },
    });

    return withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(Page),
    )
};

export default asPage;
