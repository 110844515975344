import React from "react";

import { HeaderTitle, SubTitle } from '../Typography'
import { Wrapper, About, ContactWrapper, Contact, ContactIconWrapper, ContactIcon, ContactItem, ContactList, ContactListItem } from './Header.style'

import { contact } from './Header.constants'

const Header = () => {
    return (
        <Wrapper>
            <HeaderTitle dark>TIM RIJAVEC</HeaderTitle>
            <SubTitle dark>Software Engineer and a passionate traveler.</SubTitle>
            <About dark>
                I currently work as a Principal Engineer at TrustedHousesitters.com, overseeing the development of the Mobile App and Web.<br />
                To me, building software means to provide tools to people, so, that they can focus on using their unique abilities to provide more value.<br />
                My childhood me, always dreamed of being a game developer, but since the new era of smartphones, I found my new me in the mobile world.
            </About>
            <About dark></About>
            <ContactWrapper>
                {contact.map((item, i) => (
                    <Contact key={`contact-${i}`}>
                        <ContactIconWrapper>
                            <ContactIcon src={item.icon} />
                        </ContactIconWrapper>
                        <ContactItem>
                            <ContactList>
                                {item.items.map((k, j) => (
                                    <ContactListItem key={`listkey-${j}`}>{k}</ContactListItem>
                                ))}
                            </ContactList>
                        </ContactItem>
                    </Contact>
                ))}
            </ContactWrapper>
        </Wrapper>
    );
};

export { Header };
export default Header;

