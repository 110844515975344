import React, { Fragment } from 'react';
import Helmet from 'react-helmet';

import asPage from '../../components/Page';

import AboutMe from '../../components/resumeATS/AboutMe';
import Education from '../../components/resumeATS/Education';
import Experiences from '../../components/resumeATS/Experiences';
import Header from '../../components/resumeATS/Header';
import SocialMedia from '../../components/resumeATS/SocialMedia';
import Interest from '../../components/resumeATS/Interest';
import MySkills from '../../components/resumeATS/MySkills';

import { LayoutMainPage, LayoutSecondPage, Side, Main, MainSecond, TitleStyled, TitleWrapperStyled } from './ResumeATS.style';

const ResumeATS = () => {
    const includeNewestPosition = new Date() > new Date('2020-05-01');
    const fromA = includeNewestPosition ? 0 : 1;
    const toA = includeNewestPosition ? 1 : 2;

    const fromB = includeNewestPosition ? 1 : 2;
    const toB = includeNewestPosition ? 8 : 9;

    return (
        <Fragment>
            <LayoutMainPage>
                <Helmet>
                    <title>Tim Rijavec - Tech Lead & Manager</title>
                    <style>
                        {`
                        @page {
                            size: A4 portrait;
                            margin: 0cm;
                        }
                        body {
                            -webkit-print-color-adjust: exact !important;
                        }
                        `}
                    </style>
                </Helmet>
                <Main>
                    <Header />
                    <MySkills />
                    <TitleWrapperStyled>
                        <TitleStyled dark>Professional Experience and Achievements</TitleStyled>
                    </TitleWrapperStyled>
                    <Experiences experiencesFrom={fromA} experiencesTo={toA} full />
                    {/* <AboutMe />
                    <SocialMedia /> */}
                </Main>
            </LayoutMainPage>
            <LayoutSecondPage>
                <MainSecond>
                    <Experiences experiencesFrom={fromB} experiencesTo={toB} full />
                </MainSecond>
            </LayoutSecondPage>
            {/* <LayoutSecondPage>
                <Side />
                <MainSecond>
                    <Experiences experiencesFrom={0} experiencesTo={1} otherPositions title="Job Experience - Previous" />
                    <Experiences experiencesFrom={1} experiencesTo={5} title="" />
                </MainSecond>
            </LayoutSecondPage> */}
            <LayoutSecondPage>
                <MainSecond>
                    <TitleWrapperStyled>
                        <TitleStyled dark>Education</TitleStyled>
                    </TitleWrapperStyled>
                    <Education />
                    <TitleWrapperStyled>
                        <TitleStyled dark>Interests</TitleStyled>
                    </TitleWrapperStyled>
                    <Interest />
                </MainSecond>
            </LayoutSecondPage>
        </Fragment>
    );
};

export default asPage(ResumeATS, 'ResumeATS', true);
