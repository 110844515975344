import React from "react";

import { HeaderTitle, SubTitle } from '../Typography'
import { Wrapper, About, ContactWrapper, Contact, ContactIconWrapper, ContactIcon, ContactItem, ContactList, ContactListItem } from './Header.style'

import { contact } from './Header.constants'

const Header = () => {
    return (
        <Wrapper>
            <HeaderTitle dark>TIM RIJAVEC</HeaderTitle>
            <SubTitle dark>Software Engineer and a passionate traveler.</SubTitle>
            <About dark>I currently work as a Principal Engineer at TrustedHousesitters.com, overseeing development of both the Mobile App and Web.</About>
            <ContactWrapper>
                {contact.map((item, i) => (
                    <Contact key={`contact-${i}`}>
                        <ContactIconWrapper>
                            <ContactIcon src={item.icon} />
                        </ContactIconWrapper>
                        <ContactItem>
                            <ContactList>
                                {item.items.map((k, j) => (
                                    <ContactListItem key={`listkey-${j}`}>{k}</ContactListItem>
                                ))}
                            </ContactList>
                        </ContactItem>
                    </Contact>
                ))}
            </ContactWrapper>
        </Wrapper>
    );
};

export { Header };
export default Header;

