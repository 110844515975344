import styled, { css } from 'styled-components';

import { Wrapper, Title, Description, List, ListItem, SubTitle } from '../Typography'

const PositionWrapper = styled(Wrapper)`
    margin-bottom: ${({ theme }) => theme.spacing[6] };
`;

const PositionTitle = styled(Title)`
    border-bottom: none;
    margin-bottom: ${({ theme }) => theme.spacing[3] };
    margin-left: ${({ theme }) => theme.spacing[4] };
    width: auto;
`;

const PositionSubTitle = styled(SubTitle)`
    margin-bottom: ${({ theme }) => theme.spacing[0.5] };
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
`;

const PositionList = styled(List)`
    padding: 0;
    margin: 0 0 0 ${({ theme }) => theme.spacing[4] };
    position: relative;
`;

const PositionDescription = styled(Description)`
    padding: ${({ theme }) => theme.spacing[1] } 0 0 0;
    margin: 0;
`;

const PositionDate = styled(Description)`
    margin: 0;
    color: ${({ theme }) => theme.palette.darkerGrey };
    text-align: right;
`;

const PositionOtherDate = styled(Description)`
    margin: 0;
    color: ${({ theme }) => theme.palette.darkerGrey };
    font-size: ${({ theme }) => theme.fontSizes.xsmall };
    text-align: right;
`;

const PositionListItem = styled(ListItem)`
    color: ${({ theme }) => theme.palette.darkerGrey };
    font-size: ${({ theme }) => theme.fontSizes.small };
    line-height: 15px;
    position: relative;
    margin-bottom: ${({ theme }) => theme.spacing[3] };
    
    &::before {
        content: ' ';
        position: absolute;
        top: 1px;
        left: -27px;
        background-color: ${({ theme }) => theme.palette.darkerGrey};
        width: 8px;
        height: 8px;
        border-radius: 8px;
    }

    &::after {
        content: ' ';
        font-size: 1rem;
        line-height: 1.5;
        font-weight: 700;
        position: absolute;
        left: -24px;
        top: 16px;
        bottom: 0;
        background-color: ${({ theme }) => theme.palette.darkerGrey};
        width: 2px;
    }
`;

const OtherPositionList = styled(List)`
    padding: 0 0 ${({ theme }) => theme.spacing[1] } 0;
    margin: 0;
    position: relative;
`;

const OtherPositionListItem = styled(ListItem)`
    color: ${({ theme }) => theme.palette.darkerGrey };
    font-size: ${({ theme }) => theme.fontSizes.small };
    margin-bottom: ${({ theme }) => theme.spacing[0.5] };
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
`;

export {
    PositionWrapper, PositionTitle, PositionDescription, PositionList, PositionSubTitle, PositionDate,
    PositionOtherDate, PositionListItem, OtherPositionList, OtherPositionListItem,
};
