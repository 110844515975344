import React, { PureComponent } from "react";

import i18next from 'i18next';

import { connect } from 'react-redux';
import { setLocale } from '../../actions';

import {
    Languages, LangImg,
} from './LanguagesSelector.style';

type Props = {
    setLocale: (string) => {},
};

const LANGUAGE_TO_COUNTRY = {
    'de-DE': 'German',
    'en-GB': 'English',
    'sl-SI': 'Slovenian',
};

class LanguagesSelector extends PureComponent<Props> {
    setLanguage = (locale) => {
        this.props.setLocale(locale);
    };

    render() {
        const availableLanguages = Object.keys(i18next.options.resources);
        if (availableLanguages.length < 2) return null;

        return (
            <Languages>
                {availableLanguages.map(language => (
                    <LangImg
                        key={language}
                        src={require(`../../assets/flags/${language}.png`)}
                        alt={LANGUAGE_TO_COUNTRY[language]}
                        onClick={() => this.setLanguage(language)}
                    />
                ))}
            </Languages>
        );
    }
}


const mapDispatchToProps = {
    setLocale,
};

export { LanguagesSelector };
export default connect(
    null,
    mapDispatchToProps
)(LanguagesSelector);
