import styled from 'styled-components';

import { Link as RouterLink } from 'react-router-dom';

// approach of passing in Object to styled components is similar to https://www.styled-components.com/docs/advanced#tagged-template-literals
// ref: https://github.com/styled-components/styled-components/pull/1775
const H1 = styled.h1`
    ${({ theme, as }) => (as ? theme.typography[[as]] : theme.typography[['h2']])};
    
    @media screen and (min-width: 720px) {
        ${({ theme, as }) => (as ? theme.typography[[as]] : theme.typography[['h1']])};
    }
`;

const H21 = styled.h2`
    ${({ theme, as }) => (as ? theme.typography[[as]] : theme.typography[['h2']])};
    
    @media screen and (min-width: 720px) {
        ${({ theme, as }) => (as ? theme.typography[[as]] : theme.typography[['h1']])};
    }
`;

// const H1 = styled.h1([({ theme, as }) => (as ? theme.typography[[as]] : theme.typography[['h1']])]);

const H2 = styled.h2([({ theme, as }) => (as ? theme.typography[[as]] : theme.typography[['h2']])]);

const H3 = styled.h3([({ theme, as }) => (as ? theme.typography[[as]] : theme.typography[['h3']])]);

const H4 = styled.h4([({ theme, as }) => (as ? theme.typography[[as]] : theme.typography[['h4']])]);

const H5 = styled.h4([({ theme, as }) => (as ? theme.typography[[as]] : theme.typography[['h5']])]);

const P = styled.p([({ theme, as }) => (as ? theme.typography[[as]] : theme.typography[['p']])]);

const Span = styled.span([({ theme, as }) => (as ? theme.typography[[as]] : theme.typography[['span']])]);

const Label = styled.label([({ theme, as }) => (as ? theme.typography[[as]] : theme.typography[['label']])]);

const Blockquote = styled.blockquote([
    ({ theme, as }) => (as ? theme.typography[[as]] : theme.typography[['blockquote']]),
]);

const A = styled.a([({ theme, as }) => (as ? theme.typography[[as]] : theme.typography[['a']])]);

const Link = styled(RouterLink)([({ theme, as }) => (as ? theme.typography[[as]] : theme.typography[['a']])]);

export { H1, H21, H2, H3, H4, H5, P, Span, Label, Blockquote, A, Link };
