import { combineReducers } from 'redux';
import { routerReducer as router } from 'react-router-redux';

import app from '../reducers/app';
import experience from '../reducers/experience';
import experiences from '../reducers/experiences';

import page from '../components/Page/reducers';

const rootReducer = combineReducers({
    app,
    experience,
    experiences,
    page,
    router,
});

export default rootReducer;
