import styled, { css } from 'styled-components';

import { Wrapper, Title, Description, List, ListItem, SubTitle } from '../Typography'

const InterestWrapper = styled(Wrapper)`
    @media print{
        padding-top: ${({ theme }) => theme.spacing[6] };
    }
`;

const InterestTitle = styled(Title)`
    border-bottom: none;
    margin-bottom: ${({ theme }) => theme.spacing[3] };
    margin-left: ${({ theme }) => theme.spacing[4] };
    width: auto;
`;

const InterestSubTitle = styled(SubTitle)`
    margin-bottom: ${({ theme }) => theme.spacing[0.5] };
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
`;

const InterestList = styled(List)`
    padding: 0;
    margin: 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
`;

const InterestDescription = styled(Description)`
    padding: ${({ theme }) => theme.spacing[1] } 0 0 0;
    margin: 0;
`;

const InterestDate = styled(Description)`
    margin: 0;
    color: ${({ theme }) => theme.palette.darkerGrey };
    text-align: right;
`;

const InterestListItem = styled(ListItem)`
    color: ${({ theme }) => theme.palette.darkerGrey };
    font-size: ${({ theme }) => theme.fontSizes.small };
    line-height: 15px;
    position: relative;
    margin: 0 0 ${({ theme }) => theme.spacing[3] } ${({ theme }) => theme.spacing[4] };
    flex: 1 0 calc(50% - ${({ theme }) => theme.spacing[4] });
    
    &::before {
        content: ' ';
        position: absolute;
        top: 1px;
        left: -27px;
        background-color: ${({ theme }) => theme.palette.grey};
        width: 8px;
        height: 8px;
        border-radius: 8px;
    }

    &::after {
        content: ' ';
        font-size: 1rem;
        line-height: 1.5;
        font-weight: 700;
        position: absolute;
        left: -24px;
        top: 16px;
        bottom: 0;
        background-color: ${({ theme }) => theme.palette.grey};
        width: 2px;
    }
`;


export {
    InterestWrapper, InterestTitle, InterestDescription, InterestList, InterestSubTitle, InterestDate,
    InterestListItem,
};
