import React from "react";

import { Wrapper, Title, Description } from '../Typography'

const AboutMe = () => {
    return (
        <Wrapper>
            <Title>ABOUT ME</Title>
            <Description>I'm Tim Rijavec, developer, deviser, dreamer, inventor, maker, incessant learner and a passionate traveler.</Description>
        </Wrapper>
    );
};

export { AboutMe };
export default AboutMe;

