import React, { Fragment } from 'react';
import Helmet from 'react-helmet';

import { connect } from 'react-redux';
import asPage from '../../components/Page'

import Experiences from '../../components/Experiences';
import { getExperience } from "../../selectors";
// import { translate } from "react-i18next";

const Experience = ({ match, history, experience }) => {
    const { params } = match;
    const { slug } = params;

    if (!experience) return null;

    const { company, about, companyLogo } = experience;
    if (!company) return null;

    return (
        <Fragment>
            <Helmet>
                <title>{`Experience - ${company}`}</title>

                <meta property="og:url"                content={`https://www.coder.si/experience/${slug}/`} />
                <meta property="og:type"               content="website" />
                <meta property="og:title"              content={`Tim Rijavec - Experience - ${company} – Coder.si`} />
                <meta property="og:description"        content={about} />
                <meta property="og:image"              content={`https://www.coder.si/static/assets/experiences/${companyLogo}`} />
            </Helmet>
            <Experiences slug={slug} history={history} />
        </Fragment>
    );
};

const mapStateToProps = state => ({
    experience: getExperience(state),
});

export default asPage(connect(mapStateToProps, null)(Experience), 'Experience', true);
