import styled, { css } from 'styled-components';

import { Wrapper, Title, Description, List, ListItem, SubTitle } from '../Typography'

const EducationWrapper = styled(Wrapper)`
`;

const EducationTitle = styled(Title)`
    border-bottom: none;
    margin-bottom: ${({ theme }) => theme.spacing[3] };
    margin-left: ${({ theme }) => theme.spacing[4] };
    width: auto;
`;

const EducationSubTitle = styled(SubTitle)`
    margin-bottom: ${({ theme }) => theme.spacing[0.5] };
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
`;

const EducationList = styled(List)`
    padding: 0;
    margin: 0 0 0 ${({ theme }) => theme.spacing[4] };
    position: relative;
`;

const EducationDescription = styled(Description)`
    padding: ${({ theme }) => theme.spacing[1] } 0 0 0;
    margin: 0;
`;

const EducationDate = styled(Description)`
    margin: 0;
    color: ${({ theme }) => theme.palette.darkerGrey };
    text-align: right;
`;

const EducationListItem = styled(ListItem)`
    color: ${({ theme }) => theme.palette.darkerGrey };
    font-size: ${({ theme }) => theme.fontSizes.small };
    line-height: 15px;
    position: relative;
    margin-bottom: ${({ theme }) => theme.spacing[3] };
    
    &::before {
        content: ' ';
        position: absolute;
        top: 1px;
        left: -27px;
        background-color: ${({ theme }) => theme.palette.darkerGrey};
        width: 8px;
        height: 8px;
        border-radius: 8px;
    }

    &::after {
        content: ' ';
        font-size: 1rem;
        line-height: 1.5;
        font-weight: 700;
        position: absolute;
        left: -24px;
        top: 16px;
        bottom: 0;
        background-color: ${({ theme }) => theme.palette.darkerGrey};
        width: 2px;
    }
`;


export {
    EducationWrapper, EducationTitle, EducationDescription, EducationList, EducationSubTitle, EducationDate,
    EducationListItem,
};
