import { injectGlobal } from 'styled-components';
import sanitizedStyles from './sanitize';
import typography from './typography';

export default () => {
    /* eslint-disable no-unused-expressions */
    injectGlobal`
        ${sanitizedStyles}
        ${typography}

        fieldset {
            border: 0;
            padding: 0;
        }

        p {
            white-space: pre-line;
        }

        //.star {
        //    display: inline-block;
        //    margin-left: 0.5rem;
        //    transform: scale(1.25);
        //}
        //.star-enter {
        //    opacity: 0.01;
        //    transform: translateY(-100%) scale(0.75);
        //}
        //.star-enter-active {
        //    opacity: 1;
        //    transform: translateY(0%) scale(1.25);
        //    transition: all 300ms ease-out;
        //}
        //.star-exit {
        //    opacity: 1;
        //    transform: scale(1.25);
        //}
        //.star-exit-active {
        //    opacity: 0;
        //    transform: scale(4);
        //    transition: all 300ms ease-in;
        //}
        //
        //.message-enter {
        //    opacity: 0.01;
        //    //transform: scale(2);
        //}
        //.message-enter-active {
        //    opacity: 1;
        //    //transform: scale(1);
        //    transition: all 250ms ease-out;
        //}
        //.message-exit {
        //    opacity: 1;
        //    //transform: scale(1);
        //}
        //.message-exit-active {
        //    opacity: 0.01;
        //    //transform: scale(2);
        //    transition: all 250ms ease-out;
        //}
    `;
};
