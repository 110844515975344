import React, { Fragment } from "react";

import { translate } from 'react-i18next';

import {
    InterestWrapper, InterestTitle,
    InterestList, InterestDescription, InterestSubTitle, InterestListItem, InterestDate,
} from './Interest.style';

import { interests } from './Interest.constants';

const Interest = ({ t }) => {

    const renderItem = (interest) => {
        return (
            <Fragment>
                <InterestSubTitle dark small>
                    {t(`components:interest.${interest}`)}
                </InterestSubTitle>
                <InterestDescription dark>{t(`components:interest.${interest}_description`)}</InterestDescription>
            </Fragment>
        );
    };

    return (
        <InterestWrapper>
            {/* <InterestTitle dark>INTERESTS</InterestTitle> */}
            <InterestList>
                {interests.map(interest => (
                    <InterestListItem key={interest}>
                        {renderItem(interest)}
                    </InterestListItem>
                ))}
            </InterestList>
        </InterestWrapper>
    );
};

export { Interest };
export default translate()(Interest);

