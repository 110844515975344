import styled, { css } from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
    padding: 0 25px;
`;

const HeaderTitle = styled.div`
    width: 100%;
    color: ${({ theme, dark }) => dark ? theme.palette.darkerGrey : theme.palette.white };
    font-size: 28px;
    font-weight: 600;
`;

const Title = styled.div`
    width: 100%;
    color: ${({ theme, dark }) => dark ? theme.palette.darkerGrey : theme.palette.white };
    font-size: 20px;
    border-bottom: 1px solid ${({ theme, dark }) => dark ? theme.palette.darkerGrey : theme.palette.white };
    margin-bottom: ${({ theme }) => theme.spacing[2] };
    font-weight: 600;
`;

const SubTitle = styled.div`
    width: 100%;
    color: ${({ theme, dark }) => dark ? theme.palette.darkerGrey : theme.palette.white };
    font-size: 16px;
    margin-bottom: ${({ theme, noMargin }) => noMargin ? '0px' : theme.spacing[1] };
    font-weight: 600;
`;

const SubSubTitle = styled.div`
    width: 100%;
    color: ${({ theme, dark }) => dark ? theme.palette.darkerGrey : theme.palette.white };
    font-size: 14px;
    margin-bottom: ${({ theme, noMargin }) => noMargin ? '0px' : theme.spacing[1] };
    font-weight: 600;
`;

const Description = styled.div`
    width: 100%;
    color: ${({ theme, dark }) => dark ? theme.palette.darkerGrey : theme.palette.white };
    font-size: 14px;
    margin-bottom: ${({ theme }) => theme.spacing[2] };
`;

const List = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: ${({ theme }) => theme.spacing[3] };
`;

const ListItem = styled.li`
    color: ${({ theme, dark }) => dark ? theme.palette.darkerGrey : theme.palette.white };
    width: 100%;
    font-size: 14px;
    margin-bottom: ${({ theme, withSpace }) => withSpace ? theme.spacing[1] : '0px' };
    text-align: left;
`;

const Link = styled.a`
    color: ${({ theme, dark }) => dark ? theme.palette.darkerGrey : theme.palette.white };
    text-decoration: none;
`;

export { Wrapper, HeaderTitle, Title, SubTitle, SubSubTitle, Description, List, ListItem, Link };
