import colors from './colors';

const palette = {
    primary: colors.green.primary.hex,
    primaryDark: colors.green.dark.hex,
    primarySoft: colors.green.soft.hex,
    accent: colors.pink.primary.hex,
    danger: colors.red.dark.hex,
    text: colors.grey.dark.hex,
    textLight: colors.grey.primary.hex,
    textWhite: colors.white.primary.hex,
    link: colors.blue.primary.hex,
    linkSoft: colors.blue.soft.hex,
    linkDark: colors.blue.dark.hex,
    icon: colors.grey.primary.hex,
    border: colors.grey.soft.hex,
    borderDark: colors.grey.primary.hex,
    highlight: colors.white.dark.hex,
    hoverGrey: colors.grey.softer.hex,
    white: colors.white.primary.hex,
    black: colors.black.primary.hex,
    grey: colors.grey.primary.hex,
    darkerGrey: colors.grey.darker.hex,
};

const theme = {
    palette,
    spacing: {
        // padding and margins
        0: '0',
        0.5: '4px',
        1: '8px',
        1.5: '12px',
        2: '16px',
        2.5: '20px',
        3: '24px',
        3.5: '28px',
        4: '32px',
        4.5: '36px',
        5: '40px',
        5.5: '44px',
        6: '48px',
        7: '56px',
        8: '64px',
        9: '72px',
        10: '80px',
        11: '88px',
        12: '96px',
    },
    borderRadius: {
        0: '0',
        0.5: '4px',
        1: '8px',
        1.5: '12px',
        2: '16px',
        2.5: '20px',
    },
    fontFamily: 'Muli, sans-serif',
    fontSizes: {
        xsmall: '0.625rem',
        small: '0.75rem',
        medium: '1rem',
        large: '1.5rem',
        xlarge: '2rem',
    },
    typography: {
        h1: {
            /* font-size: 32px */
            /* line-height: 48px */
            'font-size': '2rem',
            'line-height': '1.2',
            'font-weight': '700',
            color: `${palette.text}`,
        },
        h2: {
            /* font-size: 24px */
            /* line-height: 24px */
            'font-size': '1.5rem',
            'line-height': '1',
            'font-weight': '700',
            color: `${palette.text}`,
        },
        h3: {
            /* font-size: 20px */
            /* line-height: 24px */
            'font-size': '1.25rem;',
            'line-height': '1.2',
            'font-weight': '700',
            color: `${palette.text}`,
        },
        h4: {
            /* font-size: 17px */
            /* line-height: 24px */
            'font-size': '1.0625rem',
            'line-height': '1.4117647058',
            'font-weight': '700',
            color: `${palette.text}`,
        },
        h5: {
            /* font-size: 17px */
            /* line-height: 24px */
            'font-size': '1rem',
            'line-height': '1.5',
            'font-weight': '700',
            color: `${palette.text}`,
        },
        p: {
            /* font-size: 16px */
            /* line-height: 24px */
            'font-size': '1rem',
            'line-height': '1.5',
            'font-weight': '300',
        },
        span: {
            /* font-size: 16px */
            /* line-height: 24px */
            'font-size': '1rem',
            'line-height': '1.5',
            'font-weight': '300',
        },
        label: {
            'font-size': '1rem',
            'line-height': '1.5',
            'font-weight': '700',
            color: `${palette.text}`,
        },
        blockquote: {
            /* font-size: 40px */
            /* line-height: 48px */
            'font-size': '2.5rem',
            'line-height': '1.2',
            'font-weight': '300',
        },
        smallText: {
            /* font-size: 14px */
            /* line-height: 24px */
            'font-size': '0.875rem',
            'line-height': '1.71428571429',
        },
        a: {
            color: 'blue',
        },
    },
    // https://material.io/guidelines/layout/responsive-ui.html#responsive-ui-breakpoints
    breakpoints: {
        xsmall: '480',
        small: '600',
        medium: '840',
        large: '960',
        xlarge: '1280',
        xxlarge: '1440',
    },
    screenSize: {
        tablet: 720,
        desktop: 960,
    },
    zIndexLayers: ['tooltip', 'modal'],
    disabledOpacity: 0.54, // adds an opacity to indicate a component is disabled
    activeOpacity: 0.78, // blend value for button hover and active states
};

export default theme;
