import { all, fork } from 'redux-saga/effects';

import home from './Home/sagas';
import experience from './Experience/sagas';
import resume from './Resume/sagas';
import resumeFull from './ResumeFull/sagas';
import resumeATS from './ResumeATS/sagas';

export default function* () {
    yield all([fork(home), fork(experience), fork(resume), fork(resumeFull), fork(resumeATS)]);
}
