// Import images
import email from './assets/img/email.png';
import location from './assets/img/location.png';
import phone from './assets/img/phone.png';

const contact = [
    {
        icon: location,
        items: [
            'Brighton, England'
        ],
    },
    {
        icon: phone,
        items: [
            '+44 7884 428 575'
        ],
    },
    {
        icon: email,
        items: [
            'tim@coder.si'
        ],
    },
];

export {
    contact,
};
