import { fork, call, all } from 'redux-saga/effects';

import pagesSaga from '../pages/sagas';
import experienceSagas from './experiences';

export default function* rootSaga() {
    yield all([fork(experienceSagas), fork(pagesSaga)]);
}

const waitAll = sagasWithArgsToWaitFor =>
    function* waitAllGenerator() {
        yield all(sagasWithArgsToWaitFor.map(({ saga, args }) => call(saga, args)));
    };

export { waitAll };
