import React from 'react';
import createHistory from 'history/createBrowserHistory';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { ConnectedRouter as Router, routerMiddleware } from 'react-router-redux';
import IntlProvider from '../shared/i18n/IntlProvider';
import { configureStore } from '../shared/store';
import routes, { ContextProvider } from '../shared/routes';
import { configureEnvironment, getBrowserEnvironment } from '../shared/environment';

configureEnvironment(getBrowserEnvironment());

const browserHistory = window.browserHistory || createHistory();
const { store } = configureStore({
    initialState: JSON.parse(window.__PRELOADED_STATE__),
    history: browserHistory,
});

// const store =
//     window.store ||
//     configureStore({
//         initialState: window.__PRELOADED_STATE__,
//         middleware: [routerMiddleware(browserHistory)],
//     });

const context = {};

// const renderApp = context => (
//     <Provider store={store}>
//         <Router history={browserHistory}>
//             <IntlProvider>
//                 <ContextProvider context={context}>{renderRoutes(routes)}</ContextProvider>
//             </IntlProvider>
//         </Router>
//     </Provider>
// );


const renderApp = context => (
    <Provider store={store}>
        <Router history={browserHistory}>
            <IntlProvider>
                <ContextProvider context={context}>{renderRoutes(routes)}</ContextProvider>
            </IntlProvider>
        </Router>
    </Provider>
);

const root = document.getElementById('app');
hydrate(renderApp(context), root);

// hydrate(
//     <Provider store={store}>
//         <Router history={browserHistory}>
//             <IntlProvider>
//                 <App />
//             </IntlProvider>
//         </Router>
//     </Provider>,
//     document.getElementById('app')
// );

// if (process.env.NODE_ENV === 'development') {
//     if (module.hot) {
//         module.hot.accept('../shared/routes.js', () => {
//             /* eslint-disable global-require */
//             require('../shared/routes.js');
//             console.log('context', context)
//             hydrate(renderApp(context), root);
//         });
//     }
//
//     if (!window.store || !window.browserHistory) {
//         window.browserHistory = browserHistory;
//         window.store = store;
//     }
// }


if (process.env.NODE_ENV === 'development') {
    if (module.hot) {
        module.hot.accept();
    }

    module.hot.accept('../shared/routes.js', () => {
        require('../shared/routes.js');
        hydrate(renderApp(context), root);
    });

    if (!window.store || !window.browserHistory) {
        window.browserHistory = browserHistory;
        window.store = store;
    }
}
