import { put, takeEvery, all, take } from 'redux-saga/effects';

import * as pageActions from '../../components/Page/actions';
import { ActionTypes, loadExperience } from '../../actions';

export function* loadExperienceData({ params }) {
    yield put(loadExperience(params.slug));
}

function* load(action) {
    yield loadExperienceData(action);
    yield put(pageActions.loaded.create('Experience'));
}

function* preload(action) {
    yield loadExperienceData(action);
    // Block until post is loaded
    yield take([ActionTypes.EXPERIENCE_LOADED]);
    yield put(pageActions.preloaded.create('Experience'));
}

export default function* pageSaga() {
    yield all([
        takeEvery(action => action.type === pageActions.preload.ACTION && action.pageId === 'Experience', preload),
        takeEvery(action => action.type === pageActions.load.ACTION && action.pageId === 'Experience', load),
    ]);
}
