import styled, { css } from 'styled-components';

import { Title, List, ListItem } from '../Typography';

const Wrapper = styled.div`
    width: 100%;
    padding: 0 25px;
    height: 700px;
`;

const TitleStyled = styled(Title)`
    text-align: center;
    color: ${({ theme }) => theme.palette.darkerGrey};
    border-bottom: 1px solid #808080;
    margin-bottom: 25px;
`;

const ListStyled = styled(List)`

`;

const ListItemStyled = styled(ListItem)`
    display: inline-block;
    width: auto;
    padding-right: ${({ theme }) => theme.spacing[1]};
    color: ${({ theme }) => theme.palette.darkerGrey};

    &:not(:first-child)::before {
        content: '•';
        padding-right: ${({ theme }) => theme.spacing[1]};
    }
`;

const Description = styled.div`
    width: 100%;
    color: white;
    font-size: 14px;
    text-align: justify;
    margin-bottom: 16px;
`;

export { Wrapper, TitleStyled, Description, ListStyled, ListItemStyled };