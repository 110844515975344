import React, { Fragment } from "react";

import asPage from '../../components/Page'

import Intro from '../../components/Intro';
import MyBackground from '../../components/MyBackground';
import Skills from '../../components/Skills';
import Experiences from '../../components/Experiences';
import Education from '../../components/Education';

const Home = () => {
    return (
        <Fragment>
            <Intro />
            <MyBackground />
            <Skills />
            <Experiences />
            <Education />
        </Fragment>
    );
};

export default asPage(Home, 'Home', true);
