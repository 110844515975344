import styled from 'styled-components';

const Section = styled.section`
    display: flex;
    justify-content: center;
    flex-flow: column;
    padding: 100px 30px;
    max-width: 1440px;
    margin: 0 auto;

    @media screen and (min-width: 480px) {
        padding: 100px 50px;
    }
    
    @media screen and (min-width: 600px) {
        padding: 120px 100px 0;
    }
`;

export {
    Section,
};
