import styled, { css } from 'styled-components';

import { H1, H2, H3 } from '../UI/Typography';

import Icon from '../Icon';

const WrapperStyled = styled.div`
    background-color: #F9F9FB;
`;

const HeaderStyled = styled.header`
    padding: 100px 30px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 1440px;
    margin: 0 auto;
    
    @media screen and (min-width: 480px) {
        padding: 100px 50px;
    }
    
    @media screen and (min-width: 600px) {
        padding: 120px 100px;
    }
`;

const HeaderContent = styled.div`
    max-height: 500px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

const H1H2Common = css`
    font-size: 1.4rem;
    line-height: 1.5;
        
    @media screen and (min-width: 360px) {
        font-size: 1.75rem;
    }

    @media screen and (min-width: 550px) {
        font-size: 2rem;
    }

    @media screen and (min-width: 768px) {
        font-size: 2.25rem;
    }

    @media screen and (min-width: 1024px) {
        font-size: 2.5rem;
    }
`;

const H1Styled = styled(H1)`
    ${H1H2Common};
    font-weight: 300;
`;

const H2Styled = styled(H2)`
    ${H1H2Common};
    font-weight: 300;
    max-width: 700px;
`;

const Description = styled.div`
    line-height: 1.2;
    font-size: 1.25rem;
    font-weight: 700;
    color: ${({ theme }) => theme.palette.text};
`;

const Strong = styled.strong`
    ${H1H2Common};
    font-weight: 700;
`;

const Link = styled.a`
    font-size: 1.25rem;
    transition: all .2s ease-in-out;
    font-weight: 300;
    text-decoration: none;
    display: inline-block;
    padding: 2px 3px;
    border-bottom: 2px solid ${({ theme }) => theme.palette.linkDark};
    color: inherit;
    transition-duration: 0.3s;

    &:hover {
        border-color: transparent;
        box-shadow: 0 10px 10px -10px ${({ theme }) => theme.palette.grey};
    }
`;

const ULStyled = styled.ul`
    list-style: none;
    padding: 0;
    display: flex;
`;

const LIStyled = styled.li`
    &:nth-child(n + 2) {
        margin-left: ${({ theme }) => theme.spacing[2]};
    }
`;

const IconStyled = styled(Icon)`
    cursor: pointer;
    transition-duration: 0.3s;

    &:hover {
        transform: translateY(${({ theme }) => theme.spacing[0.5]});
    }
`;

export {
    WrapperStyled, HeaderStyled, HeaderContent,
    H1Styled, H2Styled, Description,
    Strong, Link,
    ULStyled, LIStyled,
    IconStyled,
};
