import styled, { css } from 'styled-components';

const Header = styled.header``;

const Main = styled.main`
    position: relative;
`;

const Page = styled.div`
    position: absolute;
    width: 100%;
`;

export { Main, Page };

//
// const Wrapper = styled.div`
//     margin: auto;
//     max-width: 100%;
// `;
//
// const Header = styled.h1`
//     font-size: 28px;
// `;
//
// const Logo = styled.img`
//     vertical-align: middle;
//     height: 48px;
//     width: 48px;
// `;
//
// export { Wrapper, Header, Logo };
