import { css } from 'styled-components';
import theme from './default';

export default css`
    /* latin-ext */
    @font-face {
      font-family: 'Khula';
      font-style: normal;
      font-weight: 300;
      font-display: swap;
      src: local('Khula Light'), local('Khula-Light'), url(https://fonts.gstatic.com/s/khula/v3/OpNPnoEOns3V7G-ljBvdpi9fXBXC80c.woff2) format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
      font-family: 'Khula';
      font-style: normal;
      font-weight: 300;
      font-display: swap;
      src: local('Khula Light'), local('Khula-Light'), url(https://fonts.gstatic.com/s/khula/v3/OpNPnoEOns3V7G-ljBvTpi9fXBXC.woff2) format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* latin-ext */
    @font-face {
      font-family: 'Khula';
      font-style: normal;
      font-weight: 700;
      font-display: swap;
      src: local('Khula Bold'), local('Khula-Bold'), url(https://fonts.gstatic.com/s/khula/v3/OpNPnoEOns3V7G-1ixvdpi9fXBXC80c.woff2) format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
      font-family: 'Khula';
      font-style: normal;
      font-weight: 700;
      font-display: swap;
      src: local('Khula Bold'), local('Khula-Bold'), url(https://fonts.gstatic.com/s/khula/v3/OpNPnoEOns3V7G-1ixvTpi9fXBXC.woff2) format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    /* fallback */
    @font-face {
      font-family: 'Material Icons';
      font-style: normal;
      font-weight: 400;
      src: url(https://fonts.gstatic.com/s/materialicons/v41/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
    }
    
    .material-icons {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      -webkit-font-feature-settings: 'liga';
      -webkit-font-smoothing: antialiased;
    }

    html {
        font-size: 100%;
    }
    body {
        font-family: 'Khula', sans-serif;
        font-size: 1rem;
        line-height: 1.5;
        font-weight: 300;
        color: ${theme.palette.text};
    }

    strong {
        /* font-size: 16px */
        /* line-height: 24px */
        font-size: 1rem;
        line-height: 1.5;
        font-weight: 700;
    }
`;
