import React, { Fragment } from "react";
import Helmet from 'react-helmet';

import asPage from '../../components/Page';

import Profile from '../../components/resume/Profile';
import AboutMe from '../../components/resume/AboutMe';
import Education from '../../components/resume/Education';
import Experiences from '../../components/resume/Experiences';
import Header from '../../components/resume/Header';
import SocialMedia from '../../components/resume/SocialMedia';
import Interest from '../../components/resume/Interest';
import MySkills from '../../components/resume/MySkills';

import { LayoutMainPage, LayoutSecondPage, Side, Main, MainSecond, LeftSide, RightSide } from './Resume.style'

const Resume = () => {
    return (
        <Fragment>
            {/*<LeftSide />*/}
            {/*<RightSide />*/}
            <LayoutMainPage>
                <Helmet>
                    <title>Resume</title>
                    <style>
                        {`
                        @page {
                            size: A4 portrait;
                            margin: 0cm;
                        }
                        body {
                            -webkit-print-color-adjust: exact !important;
                        }
                        `}
                    </style>
                </Helmet>
                <Side>
                    <Profile />
                    <AboutMe />
                    <MySkills />
                    <SocialMedia />
                </Side>
                <Main>
                    <Header />
                    <Experiences experiencesFrom={0} experiencesTo={4} />
                </Main>
            </LayoutMainPage>
            <LayoutSecondPage>
                <Side />
                <MainSecond>
                    <Education />
                    <Interest />
                </MainSecond>
            </LayoutSecondPage>
        </Fragment>
    );
};

export default asPage(Resume, 'Resume', true);
