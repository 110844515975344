import React, { Component } from 'react';

import {
    IconStyled
} from './Icon.style';

type Props = {
    type: string,
    className: string,
    onClick: Function,
};

class Icon extends Component<Props> {
    constructor(props) {
        super(props);
    }

    render() {
        const { type, className, onClick } = this.props;

        return <IconStyled className={className} onClick={onClick}>{type}</IconStyled>;
    }
}

export { Icon };
export default Icon;
