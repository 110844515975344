const languages = [ // software
    { key: 'javascript', url: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript' },
    { key: 'python', url: 'https://www.python.org/' },
    { key: 'java', url: 'https://www.java.com/' },
    { key: 'kotlin', url: 'https://kotlinlang.org/' },
    { key: 'objective-c', url: 'https://developer.apple.com/library/archive/documentation/Cocoa/Conceptual/ProgrammingWithObjectiveC/Introduction/Introduction.html' },
    { key: 'swift', url: 'https://developer.apple.com/swift/' },
    { key: 'c++', url: 'https://isocpp.org/' },
    { key: 'php', url: 'http://php.net/' },
    { key: 'css_sass', url: 'https://sass-lang.com/' },
    { key: 'sql', url: 'https://en.wikipedia.org/wiki/SQL' },
    { key: 'go', url: 'https://golang.org/' },
    { key: 'dart', url: 'https://dart.dev/' },
    { key: 'r', url: 'https://www.r-project.org/' },
    { key: 'xotcl', url: 'http://media.wu.ac.at/'},
];

const frameworks = [
    { key: 'react', url: 'https://reactjs.org/' },
    { key: 'react-native', url: 'https://facebook.github.io/react-native/' },
    { key: 'django', url: 'https://www.djangoproject.com/' },
    { key: 'node', url: 'https://nodejs.org/' },
    { key: 'drupal', url: 'https://www.drupal.org/' },
    { key: 'flutter', url: 'https://flutter.dev/' },
    { key: 'angular', url: 'https://angular.io/' },
    { key: 'qcodo', url: 'http://www.qcodo.com/' },
];

const tools = [
    { key: 'bash', url: 'https://www.gnu.org/software/bash/' },
    { key: 'git_github', url: 'https://github.com/' },
    { key: 'aws', url: 'https://aws.amazon.com/' },
    { key: 'mysql', url: 'https://www.mysql.com/' },
    { key: 'mongodb', url: 'https://www.mongodb.com/' },
    { key: 'webpack', url: 'https://webpack.js.org/' },
    { key: 'chrome_devtools', url: 'https://developers.google.com/web/tools/chrome-devtools/' },
    { key: 'gulp_grunt', url: 'https://gruntjs.com/' },
];

const other = [
    { key: 'xcode', url: 'https://developer.apple.com/xcode/' },
    { key: 'android', url: 'https://www.android.com/' },
    { key: 'android-studio', url: 'https://developer.android.com/studio' },
    { key: 'ios', url: 'https://developer.apple.com/ios/' },
    { key: 'vi', url: 'http://ex-vi.sourceforge.net/' },
    { key: 'sketch', url: 'https://www.sketchapp.com/' },
    { key: 'photoshop', url: 'https://www.adobe.com/products/photoshop.html' },
    { key: 'blender', url: 'https://www.blender.org/' },
    { key: 'ue4', url: 'https://www.unrealengine.com/' },
];

export {
    languages,
    frameworks,
    tools,
    other,
};

//
// Laravel
// Vue.js
// React
// Electron
// Unity Engine
//
// SOFTWARE
// Windows & Linux
// Git
// Docker
// Kubernetes
// MySQL
