import React from "react";

import { Wrapper, SubTitle } from '../Typography';
import { TitleStyled, ListStyled, ListItemStyled } from './MySkills.style';

const MySkills = () => {
    return (
        <Wrapper>
        {/* <SubTitleStyled dark>Core Competencies</SubTitleStyled>
        <About dark></About> */}
            <TitleStyled dark>Core Competencies</TitleStyled>
            <ListStyled>
                <ListItemStyled>JavaScript</ListItemStyled>
                <ListItemStyled>Python</ListItemStyled>
                <ListItemStyled>Java</ListItemStyled>
                <ListItemStyled>Objective-C</ListItemStyled>
                <ListItemStyled>Kotlin</ListItemStyled>
                <ListItemStyled>Swift</ListItemStyled>
                <ListItemStyled>Bash</ListItemStyled>
                <ListItemStyled>Go</ListItemStyled>
                <ListItemStyled>Dart</ListItemStyled>
                <ListItemStyled>iOS</ListItemStyled>
                <ListItemStyled>Android</ListItemStyled>
                <ListItemStyled>ReactJS</ListItemStyled>
                <ListItemStyled>React Native</ListItemStyled>
                <ListItemStyled>Django</ListItemStyled>
                <ListItemStyled>Docker</ListItemStyled>
                <ListItemStyled>MySQL</ListItemStyled>
                <ListItemStyled>Redis</ListItemStyled>
                <ListItemStyled>Linux</ListItemStyled>
                <ListItemStyled>Elasticsearch</ListItemStyled>
                <ListItemStyled>CI</ListItemStyled>
                <ListItemStyled>Nginx</ListItemStyled>
                <ListItemStyled>Flutter</ListItemStyled>
                <ListItemStyled>NodeJS</ListItemStyled>
                <ListItemStyled>SQL</ListItemStyled>
                <ListItemStyled>NoSQL</ListItemStyled>
                <ListItemStyled>ES</ListItemStyled>
                <ListItemStyled>AWS</ListItemStyled>
                <ListItemStyled>AWS</ListItemStyled>
                <ListItemStyled>Creative</ListItemStyled>
                <ListItemStyled>Process Flow Development</ListItemStyled>
                <ListItemStyled>Team Leadership</ListItemStyled>
                <ListItemStyled>Communication</ListItemStyled>
                <ListItemStyled>Risk Management</ListItemStyled>
                <ListItemStyled>Strategic Planning</ListItemStyled>
                <ListItemStyled>Bilingual: English, Slovenian</ListItemStyled>
            </ListStyled>
        </Wrapper>
    );
};

export { MySkills };
export default MySkills;

