/* eslint-disable import/prefer-default-export */
// @flow
import { createSelector } from 'reselect';
import type { AppT, LocaleT } from '../types';

// export const app = (state: { app: AppT }): AppT => state.app;
// export const getLocale = createSelector([app], (app): LocaleT => app.app.locale);
// export const getExperiences = createSelector([app], (app): LocaleT => app.experiences);
// export const getExperience = createSelector([app], (app): LocaleT => app.experience);

export const getLocale = state => state.app.locale;
export const getExperiences = state => state.experiences;
export const getExperience = state => state.experience;
