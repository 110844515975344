import styled, { css } from 'styled-components';

import { List, ListItem, Description } from '../Typography'

const Wrapper = styled.div`
    width: 100%;
    padding: 80px 25px 0;
`;

const About = styled(Description)`
    padding-top: ${({ theme }) => theme.spacing[0.5] };
    margin-bottom: 19px;
`;

const ContactWrapper = styled.div`
    margin: 0 0 ${({ theme }) => theme.spacing[6] };
    padding: ${({ theme }) => theme.spacing[1] } 0;
    border-top: 1px solid ${({ theme }) => theme.palette.grey };
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey };
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
`;

const Contact = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-left: ${({ theme }) => theme.spacing[1] };
    
    &:first-child {
        margin-left: 0;
    }
`;

const ContactIconWrapper = styled.div`
    width: 24px;
    height: 24px;
    border: 1px solid ${({ theme }) => theme.palette.grey };
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
`;

const ContactIcon = styled.img`
    width: 14px;
    height: 14px;
`;

const ContactItem = styled.div`
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
`;

const ContactList = styled(List)`
    padding: 0;
    margin: 0 0 0 ${({ theme }) => theme.spacing[1] };
`;

const ContactListItem = styled(ListItem)`
    color: ${({ theme }) => theme.palette.darkerGrey };
    font-size: ${({ theme }) => theme.fontSizes.small };
    line-height: 15px;
`;

export { Wrapper, About, ContactWrapper, Contact, ContactIconWrapper, ContactIcon, ContactItem, ContactList, ContactListItem };
