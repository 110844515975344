import React, { Fragment } from "react";

import { translate, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { differenceInCalendarDays, parse, format } from "date-fns";

import { getExperiences, getExperience } from '../../../selectors';

import {
    PositionWrapper, PositionTitle,
    PositionList, PositionDescription, PositionSubTitle, PositionListItem, PositionDate,
    PositionOtherDate, OtherPositionList, OtherPositionListItem, Padding,
    Stack, StackItem,
} from './Experiences.style';


const Experiences = ({ t, experiences = [], preferShortTitle, experiencesFrom, experiencesTo, full, otherPositions, title="JOB EXPERIENCE" }) => {

    const calculateExperienceDate = (positions) => {
        let startDate, endDate;

        if (positions.length === 1) {
            startDate = positions[0].startDate;
            endDate = positions[0].endDate;
        }
        else {
            startDate = positions[positions.length - 1].startDate;
            endDate = positions[0].endDate;
        }
        const dateFrom = parse(startDate);
        const dateTo = endDate ? parse(endDate) : null;
        const formattedDateTo = dateTo ? format(dateTo, 'MMM YYYY') : 'Current';

        return `${format(dateFrom, 'MMM YYYY')} - ${formattedDateTo}`;
    };

    const renderPositionTitle = (company, position, positions, index) => {
        if (index === 0) {
            return (
                <Fragment>
                    <PositionTitle dark>
                        {company}, {position.location}
                        <PositionDate>{calculateExperienceDate(positions)}</PositionDate>
                    </PositionTitle>
                    <PositionSubTitle dark>{`${position.shortTitle || position.title}`}</PositionSubTitle>
                </Fragment>
            );
        }

        return (
            <Fragment>
                <PositionSubTitle dark>{`${position.shortTitle || position.title}`}</PositionSubTitle>
            </Fragment>
        );
    }

    const renderPositionDescription = (position, accomplishments, shortAccomplishments, renderFull) => {
        const positionResponsibilities = position.responsibilitiesCV || (full ? position.responsibilities : position.responsibilities.slice(0, 6));
        // const positionResponsibilities = full ? position.responsibilities : position.responsibilities.slice(0, 6);
       
        const latestAccomplishment = shortAccomplishments || accomplishments[0];
        const hasShortAccomplishments = false; //!!shortAccomplishments;

        const stack = (full && position.stack) ? position.stack : [];

        return (
            <Fragment>
                {hasShortAccomplishments && <PositionDescription dark>{shortAccomplishments}</PositionDescription>}
                {!hasShortAccomplishments && positionResponsibilities.map(responsibility => (
                    <PositionDescription dark key={responsibility}>	• {responsibility}</PositionDescription>
                ))}
                {/* {renderFull && (
                    <Stack>
                        {stack.map((item, i) => (
                            <StackItem key={`stack_${i}`}>
                                {item}
                            </StackItem>
                        ))}
                    </Stack>
                )} */}
            </Fragment>
        );
    } 

    const renderItem = (experience, renderFull) => {
        const {
            company, positions, accomplishments, shortAccomplishments,
        } = experience;

        return (
            <Fragment>
                {positions.map((position, i) => (
                    <Padding key={`position-${company}-${i}`}>
                        {renderPositionTitle(company, position, positions, i)}
                        {renderPositionDescription(position, accomplishments, shortAccomplishments, renderFull)}
                    </Padding>
                )) }
            </Fragment>
        )
    };

    let experiencesList = experiences.slice(0, 4);
    if (experiencesFrom !== null && experiencesTo !== null) {
        experiencesList = experiences.slice(experiencesFrom, experiencesTo)
    }

    return (
        <PositionWrapper>
            <PositionList>
                {experiencesList.map((experience, i) => (
                    <PositionListItem key={experience.type}>
                        {renderItem(experience, full && i === 0)}
                    </PositionListItem>
                ))}
            </PositionList>
        </PositionWrapper>
    );
};


const mapStateToProps = state => ({
    experience: getExperience(state),
    experiences: getExperiences(state),
});

export { Experiences };
export default translate()(connect(mapStateToProps, null)(Experiences));

