import React from "react";

import { Wrapper, About, ContactWrapper, Contact, ContactIconWrapper, ContactIcon, ContactItem, ContactList, ContactListItem, HeaderTitleStyled, SubTitleStyled } from './Header.style'

import { contact } from './Header.constants'

const Header = () => {
    return (
        <Wrapper>
            <HeaderTitleStyled dark>TIM RIJAVEC</HeaderTitleStyled>
            <ContactWrapper>
                {contact.map((item, i) => (
                    <Contact key={`contact-${i}`}>
                        <ContactIconWrapper>
                            <ContactIcon src={item.icon} />
                        </ContactIconWrapper>
                        <ContactItem>
                            <ContactList>
                                {item.items.map((k, j) => (
                                    <ContactListItem key={`listkey-${j}`}>{k}</ContactListItem>
                                ))}
                            </ContactList>
                        </ContactItem>
                    </Contact>
                ))}
            </ContactWrapper>
            <About dark>
                I am resourceful, result-oriented, dedicated professional with 15+ years of experience developing and leading teams of software developers.
                Furthermore, I am a reliable and hard-working person who works hard and puts enthusiasm in all I do and I drive myself to excel in every role.
                I also have a proven record of problem-solving, analytical thinking and fast-learning curve skills.
                Deadlines are too valuable to me and I will never complete a project until the client is 100% satisfied.
            </About>
        </Wrapper>
    );
};

export { Header };
export default Header;

