import { put, take } from 'redux-saga/effects';
import { parse } from 'query-string';

import * as actions from './actions';

export default function* preload(location, pageId) {
    const { params, search, pathname } = location;

    const parsedSearch = parse(search, { arrayFormat: 'index' });
    if (parsedSearch.location) {
        parsedSearch.location = JSON.parse(parsedSearch.location);
    }

    // Emit event to do the preload
    yield put(actions.preload.create(pageId, params, parsedSearch, pathname));

    // Block until the preload generators are done
    yield take(action => action.type === actions.preloaded.ACTION && action.pageId === pageId);
}
