import styled, { css } from 'styled-components';

import { Section } from '../UI/Section';
import { Span, P } from '../UI/Typography';

const WrapperStyled = styled(Section)`
`;

const HeaderStyled = styled.header`
`;

const Strong = styled(Span)`
    font-weight: 500;

    @media screen and (min-width: 720px) {
        font-size: 1.25rem;
    }
    @media screen and (min-width: 960px) {
        font-size: 1.3rem;
    }
`;

const PStyled = styled(P)`
    max-width: 900px;
    
    @media screen and (min-width: 720px) {
        font-size: 1.25rem;
    }
    @media screen and (min-width: 960px) {
        font-size: 1.3rem;
    }
`;

export {
    WrapperStyled, HeaderStyled,
    Strong, PStyled,
};
