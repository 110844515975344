// @flow
import type { ActionT } from '../types';
import { ActionTypes } from '../actions';

export const initialState = Object.freeze({});

export default (state = initialState, action: ActionT) => {
    const { type, data } = action;

    switch (type) {
        case ActionTypes.EXPERIENCE_LOADED: {
            return {
                ...data,
            };
        }
    }

    return state;
};
