export default {
    blue: {
        primary: {
            hex: '#3cc1ca',
        },
        dark: {
            hex: '#217493',
        },
        soft: {
            hex: '#c7e1e0',
        },
        white: {
            hex: '#EAF3F5',
        },
    },
    green: {
        primary: {
            hex: '#40b147',
        },
        dark: {
            hex: '#3c8148',
        },
        soft: {
            hex: '#acdbaa',
        },
        white: {
            hex: '#EFF9EB',
        },
    },
    grey: {
        primary: {
            hex: '#808080',
        },
        darker: {
            hex: '#303030',
        },
        dark: {
            hex: '#343434',
        },
        soft: {
            hex: '#B0B0B0',
        },
        softer: {
            hex: '#E6E6E6',
        },
    },
    red: {
        primary: {
            hex: '#ef4853',
        },
        dark: {
            hex: '#db2d73',
        },
    },
    pink: {
        primary: {
            hex: '#e10aa1',
        },
    },
    white: {
        primary: {
            hex: '#FFFFFF',
        },
        dark: {
            hex: '#FAFAFA',
        },
    },
    black: {
        primary: {
            hex: '#000000',
        },
    },
    yellow: {
        primary: {
            hex: '#ffc82c',
        },
        dark: {
            hex: '#d18528',
        },
    },
};
