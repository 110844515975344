import { call, put, takeEvery, select } from 'redux-saga/effects';

import { ActionTypes } from '../actions';

import { reader } from './common';

import { getLocale } from '../selectors'

const loadExperiences = function* loadExperiences(action) {

    const locale = yield select(getLocale);

    const data = yield call(reader, {
        endpoint: `/api/experiences/?lang=${locale}`
    });

    yield put({
        type: ActionTypes.EXPERIENCES_LOADED,
        data: data.response.data,
    });

};

const loadExperience = function* loadExperience(action) {

    const locale = yield select(getLocale);

    const data = yield call(reader, {
        endpoint: `/api/experiences/${action.slug}/?lang=${locale}`
    });


    yield put({
        type: ActionTypes.EXPERIENCE_LOADED,
        data: data.response.data,
        slug: action.slug
    });

};

export default function* () {
    yield takeEvery(ActionTypes.LOAD_EXPERIENCES, loadExperiences);
    yield takeEvery(ActionTypes.LOAD_EXPERIENCE, loadExperience);
}
