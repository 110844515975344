import React from 'react';
import { ThemeProvider } from 'styled-components';

import defaultTheme from '../../themes/default';

type Props = {
    /**
     * Theme object matching the defaultTheme structure
     */
    theme: object,
    /**
     * Standard react children prop
     */
    children: mix,
};

/**
 * Custom ThemeProvider that merges a provided theme
 *  with our defaultTheme
 * @param {any} { theme, children } a theme object and react children
 */
const CoderThemeProvider = ({ theme, children } : Props) => (
    <ThemeProvider
        theme={{
            ...defaultTheme,
            ...theme,
        }}
    >
        {children}
    </ThemeProvider>
);

export default CoderThemeProvider;
