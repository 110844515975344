import React, { Fragment } from "react";

import { translate, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { differenceInCalendarDays, parse, format } from "date-fns";

import { getExperiences, getExperience } from '../../../selectors';

import {
    PositionWrapper, PositionTitle,
    PositionList, PositionDescription, PositionSubTitle, PositionListItem, PositionDate,
    PositionOtherDate, OtherPositionList, OtherPositionListItem, Padding,
    Stack, StackItem,
} from './Experiences.style';


const Experiences = ({ t, experiences = [], preferShortTitle, experiencesFrom, experiencesTo, full, otherPositions, title="JOB EXPERIENCE" }) => {

    const calculateExperienceDate = (position) => {
        const { startDate, endDate } = position;
        const dateFrom = parse(startDate);
        const dateTo = parse(endDate || new Date());

        if (dateFrom.getFullYear() === dateTo.getFullYear()) {
            return `${format(dateFrom, 'MMM')} - ${format(dateTo, 'MMM YYYY')}`;
        }
        return `${format(dateFrom, 'MMM YYYY')} - ${format(dateTo, 'MMM YYYY')}`;
    };

    const renderPositionTitle = (company, position) => {
        return (
            <Fragment>
                <PositionSubTitle dark small>
                    {`${position.shortTitle || position.title} @ ${company}`}
                    <PositionDate>{calculateExperienceDate(position)}</PositionDate>
                </PositionSubTitle>
            </Fragment>
        );
    }

    const renderOtherPositions = (positions) => {
        const otherActivePositions = positions.slice(1);

        return (
            <Fragment>
                {otherActivePositions.length > 0 && (
                    <OtherPositionList>
                        {otherActivePositions.map((pos, i) => (
                            <OtherPositionListItem dark key={`pos-${i}`}>
                                {pos.shortTitle || pos.title}
                                <PositionOtherDate>{calculateExperienceDate(pos)}</PositionOtherDate>
                            </OtherPositionListItem>
                        ))}
                    </OtherPositionList>
                )}
            </Fragment>
        );
    } 

    const renderPositionDescription= (position, accomplishments, shortAccomplishments, renderFull) => {
        const positionResponsibilities = full ? position.responsibilities : position.responsibilities.slice(0, 6);
       
        const latestAccomplishment = shortAccomplishments || accomplishments[0];
        const hasShortAccomplishments = !!shortAccomplishments;

        const stack = (full && position.stack) ? position.stack : [];

        return (
            <Fragment>
                {hasShortAccomplishments && <PositionDescription dark>{shortAccomplishments}</PositionDescription>}
                {!hasShortAccomplishments && positionResponsibilities.map(responsibility => (
                    <PositionDescription dark key={responsibility}>{responsibility}</PositionDescription>
                ))}
                {renderFull && (
                    <Stack>
                        {stack.map((item, i) => (
                            <StackItem key={`stack_${i}`}>
                                {item}
                            </StackItem>
                        ))}
                    </Stack>
                )}
            </Fragment>
        );
    } 

    const renderItem = (experience, renderFull) => {
        const {
            company, positions, accomplishments, shortAccomplishments,
        } = experience;

        const otherActivePositions = positions.slice(1);

        if (otherPositions && otherActivePositions) {
            return (
                <Fragment>
                    {otherActivePositions.map((position, i) => (
                        <Padding key={`otherPosition-${i}`}>
                            {renderPositionTitle(company, position)}
                            {renderPositionDescription(position, accomplishments, shortAccomplishments, renderFull)}
                        </Padding>
                    )) }
                </Fragment>
            )
        }

        const position = positions[0];
        return (
            <Fragment>
                {renderPositionTitle(company, position)}
                {renderOtherPositions(positions)}
                {renderPositionDescription(position, accomplishments, shortAccomplishments, renderFull)}
            </Fragment>
        );
    };

    let experiencesList = experiences.slice(0, 4);
    if (experiencesFrom !== null && experiencesTo !== null) {
        experiencesList = experiences.slice(experiencesFrom, experiencesTo)
    }

    return (
        <PositionWrapper>
            <PositionTitle dark>{title}</PositionTitle>
            <PositionList>
                {experiencesList.map((experience, i) => (
                    <PositionListItem key={experience.type}>
                        {renderItem(experience, full && i === 0)}
                    </PositionListItem>
                ))}
            </PositionList>
        </PositionWrapper>
    );
};


const mapStateToProps = state => ({
    experience: getExperience(state),
    experiences: getExperiences(state),
});

export { Experiences };
export default translate()(connect(mapStateToProps, null)(Experiences));

