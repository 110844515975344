import styled, { css } from 'styled-components';

import { Section } from '../UI/Section';
import { H4, H5 } from '../UI/Typography';

const WrapperStyled = styled(Section)`
`;

const HeaderStyled = styled.header`
`;

const ContentStyled = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const ULWrapperStyled = styled.div`
    display: flex;
    justify-content: flex-start;
    flex: 1 0 100%;
    
    @media screen and (min-width: 480px) {
        padding-left: ${({ theme }) => theme.spacing[2]};
        flex: 1 0 50%;
    }
    
    @media screen and (min-width: 750px) {
        flex: 1 0 25%;
    }
`;

const ULStyled = styled.ul`
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
`;

const LIStyled = styled.li`
    margin-bottom: ${({ theme }) => theme.spacing[6]};
`;

const EducationStyled = styled.div`
    display: flex;
`;

const EducationImageWrapper = styled.div`
    width: 84px;
    height: 60px;
    justify-content: center;
    align-items: center;
    display: flex;
`;

const EducationImage = styled.img`
    display: flex;
    max-width: 60px;
    max-height: 60px;
    width: auto;
    height: auto;
    margin-right: 24px;
`;

const EducationLink = styled.a`
    text-decoration: none;
    transition-duration: 0.3s;
    
    &:hover {
        box-shadow: 0 10px 10px -10px ${({ theme }) => theme.palette.grey};
    }
`;

const EducationInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
`;

const EducationSchool = styled(H4)`
    margin: 0;
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.linkDark};
    text-decoration: none;
`;

const EducationTitle = styled(H5)`
    margin: 0;
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.text};
    font-weight: 300;
    text-decoration: none;
`;

const EducationYear = styled.span`

`;


export {
    WrapperStyled, HeaderStyled,
    ContentStyled, ULWrapperStyled, ULStyled, LIStyled,
    EducationStyled,
    EducationImage,
    EducationImageWrapper,
    EducationInfoWrapper, EducationLink, EducationSchool, EducationTitle, EducationYear
};
